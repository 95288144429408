export default [
  {
    name: "Anton",
    link:
      "@font-face { font-family: 'Anton'; src: url('https://fonts.gstatic.com/s/anton/v11/1Ptgg87LROyAm3Kz-C8.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Architects Daughter",
    link:
      "@font-face { font-family: 'Architects Daughter'; src: url('https://fonts.gstatic.com/s/architectsdaughter/v11/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvTYlg4w.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Amatic SC",
    link:
      "@font-face { font-family: 'Amatic SC'; src: url('https://fonts.gstatic.com/s/amaticsc/v15/TUZyzwprpvBS1izr_vOECuSf.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "BioRhyme Expanded",
    link:
      "@font-face { font-family: 'BioRhyme Expanded'; src: url('https://fonts.gstatic.com/s/biorhymeexpanded/v6/i7dQIE1zZzytGswgU577CDY9LjbffxSTT3E.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Bungee Hairline",
    link:
      "@font-face { font-family: 'Bungee Hairline'; src: url('https://fonts.gstatic.com/s/bungeehairline/v6/snfys0G548t04270a_ljTLUVrv-LZxec.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Caveat",
    link:
      "@font-face { font-family: 'Caveat'; src: url('https://fonts.gstatic.com/s/caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIWpYQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Cinzel",
    link:
      "@font-face { font-family: 'Cinzel'; src: url('https://fonts.gstatic.com/s/cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnfY3lDQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Codystar",
    link:
      "@font-face { font-family: 'Codystar'; src: url('https://fonts.gstatic.com/s/codystar/v8/FwZY7-Q1xVk-40qxOu6H6Mk.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Courier Prime",
    link:
      "@font-face { font-family: 'Courier Prime'; src: url('https://fonts.gstatic.com/s/courierprime/v2/u-450q2lgwslOqpF_6gQ8kELawFpWg.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Cutive Mono",
    link:
      "@font-face { font-family: 'Cutive Mono'; src: url('https://fonts.gstatic.com/s/cutivemono/v9/m8JWjfRfY7WVjVi2E-K9H6RCTm4.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Dancing Script",
    link:
      "@font-face { font-family: 'Dancing Script'; src: url('https://fonts.gstatic.com/s/dancingscript/v13/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Federo",
    link:
      "@font-face { font-family: 'Federo'; src: url('https://fonts.gstatic.com/s/federo/v12/iJWFBX-cbD_ETsbWilmf.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Geo",
    link:
      "@font-face { font-family: 'Geo'; src: url('https://fonts.gstatic.com/s/geo/v12/CSRz4zRZluflKHpn.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Great Vibes",
    link:
      "@font-face { font-family: 'Great Vibes'; src: url('https://fonts.gstatic.com/s/greatvibes/v8/RWmMoKWR9v4ksMfaWd_JN9XFiaQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Hachi Maru Pop",
    link:
      "@font-face { font-family: 'Hachi Maru Pop'; src: url('https://fonts.gstatic.com/s/hachimarupop/v3/HI_TiYoRLqpLrEiMAuO9Ysfz7rWweN_ZpK1OtgnSaXe2-6ouLxxoAO2Opg.118.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Hi Melody",
    link:
      "@font-face { font-family: 'Hi Melody'; src: url('https://fonts.gstatic.com/s/himelody/v8/46ktlbP8Vnz0pJcqCTbEegdS3V8yduAsxfUg1BUTwBF4g3aW.119.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Indie Flower",
    link:
      "@font-face { font-family: 'Indie Flower'; src: url('https://fonts.gstatic.com/s/indieflower/v12/m8JVjfNVeKWVnh3QMuKkFcZVaUuH.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Italiana",
    link:
      "@font-face { font-family: 'Italiana'; src: url('https://fonts.gstatic.com/s/italiana/v9/QldNNTtLsx4E__B0XQmWaXw.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Josefin Slab",
    link:
      "@font-face { font-family: 'Josefin Slab'; src: url('https://fonts.gstatic.com/s/josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msR349Kg.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Julius Sans One",
    link:
      "@font-face { font-family: 'Julius Sans One'; src: url('https://fonts.gstatic.com/s/juliussansone/v9/1Pt2g8TAX_SGgBGUi0tGOYEga5WOwnsX.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Jura",
    link:
      "@font-face { font-family: 'Jura'; src: url('https://fonts.gstatic.com/s/jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7ZumR_g.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Lexend Zetta",
    link:
      "@font-face { font-family: 'Lexend Zetta'; src: url('https://fonts.gstatic.com/s/lexendzetta/v7/ll87K2KYXje7CdOFnEWcU8sYkS4f.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Lobster",
    link:
      "@font-face { font-family: 'Lobster'; src: url('https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zoKmMw.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Londrina Sketch",
    link:
      "@font-face { font-family: 'Londrina Sketch'; src: url('https://fonts.gstatic.com/s/londrinasketch/v9/c4m41npxGMTnomOHtRU68eIJn8qvXmP4.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Major Mono Display",
    link:
      "@font-face { font-family: 'Major Mono Display'; src: url('https://fonts.gstatic.com/s/majormonodisplay/v5/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRh7DXeR.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Montserrat Alternates",
    link:
      "@font-face { font-family: 'Montserrat Alternates'; src: url('https://fonts.gstatic.com/s/montserratalternates/v12/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0566fQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Nanum Myeongjo",
    link:
      "@font-face { font-family: 'Nanum Myeongjo'; src: url('https://fonts.gstatic.com/s/nanummyeongjo/v15/9Btx3DZF0dXLMZlywRbVRNhxy1LuEGI-gZ_Ll9dMHVruCTvHYAnNT2g.119.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Nova Mono",
    link:
      "@font-face { font-family: 'Nova Mono'; src: url('https://fonts.gstatic.com/s/novamono/v11/Cn-0JtiGWQ5Ajb--MRKvZ2ZZ.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Nunito",
    link:
      "@font-face { font-family: 'Nunito'; src: url('https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaB.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Pacifico",
    link:
      "@font-face { font-family: 'Pacifico'; src: url('https://fonts.gstatic.com/s/pacifico/v16/FwZY7-Qmy14u9lezJ-6H6Mk.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Parisienne",
    link:
      "@font-face { font-family: 'Parisienne'; src: url('https://fonts.gstatic.com/s/parisienne/v8/E21i_d3kivvAkxhLEVZpQyhwDw.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Patrick Hand",
    link:
      "@font-face { font-family: 'Patrick Hand'; src: url('https://fonts.gstatic.com/s/patrickhand/v14/LDI1apSQOAYtSuYWp8ZhfYe8XsLL.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Philosopher",
    link:
      "@font-face { font-family: 'Philosopher'; src: url('https://fonts.gstatic.com/s/philosopher/v14/vEFV2_5QCwIS4_Dhez5jcWBuT00.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Pinyon Script",
    link:
      "@font-face { font-family: 'Pinyon Script'; src: url('https://fonts.gstatic.com/s/pinyonscript/v11/6xKpdSJbL9-e9LuoeQiDRQR8WOXaOg.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Poiret One",
    link:
      "@font-face { font-family: 'Poiret One'; src: url('https://fonts.gstatic.com/s/poiretone/v9/UqyVK80NJXN4zfRgbdfbo55cVw.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Pompiere",
    link:
      "@font-face { font-family: 'Pompiere'; src: url('https://fonts.gstatic.com/s/pompiere/v10/VEMyRoxis5Dwuyeov5Wq7DE.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Reenie Beanie",
    link:
      "@font-face { font-family: 'Reenie Beanie'; src: url('https://fonts.gstatic.com/s/reeniebeanie/v11/z7NSdR76eDkaJKZJFkkjuvWxXPq1qw.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Roboto",
    link:
      "@font-face { font-family: 'Roboto'; src: url('https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxK.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Sacramento",
    link:
      "@font-face { font-family: 'Sacramento'; src: url('https://fonts.gstatic.com/s/sacramento/v8/buEzpo6gcdjy0EiZMBUG4C0f_Q.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Shadows Into Light",
    link:
      "@font-face { font-family: 'Shadows Into Light'; src: url('https://fonts.gstatic.com/s/shadowsintolight/v10/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQzdcD5.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Share Tech Mono",
    link:
      "@font-face { font-family: 'Share Tech Mono'; src: url('https://fonts.gstatic.com/s/sharetechmono/v10/J7aHnp1uDWRBEqV98dVQztYldFcLowEF.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Source Code Pro",
    link:
      "@font-face { font-family: 'Source Code Pro'; src: url('https://fonts.gstatic.com/s/sourcecodepro/v13/HI_SiYsKILxRpg3hIP6sJ7fM7PqlPevW.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Spartan",
    link:
      "@font-face { font-family: 'Spartan'; src: url('https://fonts.gstatic.com/s/spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrGFuV6JABQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Special Elite",
    link:
      "@font-face { font-family: 'Special Elite'; src: url('https://fonts.gstatic.com/s/specialelite/v11/XLYgIZbkc4JPUL5CVArUVL0ntnAOSA.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Swanky and Moo Moo",
    link:
      "@font-face { font-family: 'Swanky and Moo Moo'; src: url('https://fonts.gstatic.com/s/swankyandmoomoo/v10/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kksrnl.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Tangerine",
    link:
      "@font-face { font-family: 'Tangerine'; src: url('https://fonts.gstatic.com/s/tangerine/v12/IurY6Y5j_oScZZow4VOxCZZM.woff2') format('woff2'); font-weight: normal; font-style: normal; }",
  },
  {
    name: "Times New Roman",
    link: "",
  },
];
