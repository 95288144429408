export function TelefoneMask() {
  return {
    link: link,
    require: 'ngModel'
  }
  function link(scope, element, attrs, ngModelController) {
    attrs.$set('maxlength', 14)
    scope.$watch(attrs['ngModel'], applyMask)
    function applyMask(event) {
      var value = element.val().replace(/\D/g, '')
      value = value.replace(/(\d{2})(\d)/, '($1)$2')
      value = value.replace(/(\d{5})(\d)/, '$1-$2')
      value = value.replace(/(\d{4})$/, '$1')
      element.val(value)
      if ('asNumber' in attrs) {
        ngModelController.$setViewValue(
          isNaN(parseInt(value.replace(/\D/g, ''), 10))
            ? undefined
            : parseInt(value.replace(/\D/g, ''), 10))
      } else {
        ngModelController.$setViewValue(value)
      }
    }
  }
}
