export default class LoginCtrl {
	// @ngInject
	constructor($scope, $rootScope) {
		this.cidade = null
		this.pais = null

		const enviarEmail = (svgBlob1, svgBlob2, svg_width, svg_height) => {
			return new Promise((resolve, reject) => {
				var dadosLoja = JSON.parse(window.localStorage.getItem('loja'))
				var formData = new FormData()
				formData.append('file', svgBlob1)
				formData.append('file', svgBlob2)
				formData.append('svg_width', svg_width)
				formData.append('svg_height', svg_height)
				formData.append('cliente', JSON.stringify($rootScope.form))
				formData.append('loja', dadosLoja.loja)
				formData.append('email-loja', dadosLoja.email)

				$.ajax({
					type: 'POST',
					beforeSend: (request) => {
						request.setRequestHeader('access-token', window.localStorage.getItem('token'))
					},
					url: `${'@SERVER_URL@'}${'/api/v1/email/send'}`,
					data: formData,
					cache: false,
					enctype: 'multipart/form-data',
					contentType: false,
					processData: false,
					success: (data) => resolve(data),
					error: (error) => reject(error)
				})
			})
		}

		const finalizar = () => {
			$rootScope.iniciarLoading();
			const { svgComJoia, svgSemJoia, svg_width, svg_height } = $rootScope
			enviarEmail(svgComJoia, svgSemJoia, svg_width, svg_height).then((success) => {
				$rootScope.pararLoading()
				console.log('FormularioCtrl - Pedido finalizado com sucesso', success)
				$rootScope.joiaSelecionada = null
                window.imageEditorFull.clearScreen()
                $rootScope.limparLayer()
                $rootScope.resetarForm()
                $rootScope.navegar('#t1')
                document.getElementById('svgdrawer').removeAttribute('viewBox')
				iziToast.show({
					message: 'Pedido finalizado com sucesso',
					color: 'green'
				})

				// window.setTimeout(() => {
				// 	window.location.reload(false);
				// }, 2000);
			})
			.catch((error) => this._handleError(error))
		}

		this._handleError = (error) => {
            $rootScope.pararLoading()
            $rootScope.resetarForm()
            console.log('FormularioCtrl - Erro ao enviar email', error)
            iziToast.show({ message: 'Não foi possível finalizar o pedido', color: 'red' })
        }

		this.iniciarForm = () => {
			var theForm = document.getElementById('theForm')
			theForm.setAttribute('autocomplete', 'off')
			new stepsForm(theForm, {
				onSubmit: (form) => {
					$rootScope.form = {
						nome: $('#nome').val(),
						cpf: $('#cpf').val(),
						telefone: $('#telefone').val(),
						email: $('#email').val()
					}
					window.classie.addClass(theForm.querySelector('.simform-inner'), 'hide');
					var messageEl = theForm.querySelector('.final-message');
					window.classie.addClass(messageEl, 'show');
					finalizar()
				}
			})
			$rootScope.form = {}
		}

		$rootScope.resetarForm = () => {
			var theForm = document.getElementById('theForm')
			theForm.reset()
			window.classie.addClass(theForm.querySelector('.simform-inner'), 'show')
			window.classie.removeClass(theForm.querySelector('.simform-inner'), 'hide')
			window.classie.addClass(theForm.querySelector('.final-message'), 'hide')
			window.classie.removeClass(theForm.querySelector('.final-message'), 'show')
			window.classie.removeClass(theForm.querySelector('.error-message'), 'show')
			$rootScope.joiaSelecionada = null
			$('.questions li:nth-child(2)').removeClass('current')
			$('.questions li:nth-child(3)').removeClass('current')
			$('.questions li:nth-child(4)').removeClass('current')
			this.iniciarForm()
		}

		$rootScope.restartFormReinicializacao = () => {
			var theForm = document.getElementById('theForm')
			theForm.reset()
			window.classie.addClass(theForm.querySelector('.simform-inner'), 'show')
			window.classie.removeClass(theForm.querySelector('.simform-inner'), 'hide')
			window.classie.addClass(theForm.querySelector('.final-message'), 'hide')
			window.classie.removeClass(theForm.querySelector('.final-message'), 'show')
			window.classie.removeClass(theForm.querySelector('.error-message'), 'show')
			$rootScope.joiaSelecionada = null
			$('.questions li:nth-child(2)').removeClass('current')
			$('.questions li:nth-child(3)').removeClass('current')
			$('.questions li:nth-child(4)').removeClass('current')
			theForm.setAttribute('autocomplete', 'off')
			new stepsForm(theForm)
		}

		this.iniciarForm()

	}
}