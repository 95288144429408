import baseProdutos from '../base_produtos'
import fonts from '../fonts'

export default class IndexCtrl {
    // @ngInject
    constructor(Pagina, $rootScope, $scope, $timeout) {
        const navconf = {
            '#t1': {
                back: null,
                back_hide: () => true,
                forward: () => $rootScope.navegar('#t2'),
                forward_hide: () => true,
                page: 't1'
            },
            '#t2': {
                icon_left: 'glyphicon glyphicon-log-out flip-horizontal',
                icon_right: 'glyphicon glyphicon-menu-right',
                back: () => $rootScope.navegar('#t1'),
                forward: () => $rootScope.navegar('#t3'),
                forward_hide: () => !$rootScope.joiaSelecionada,
                title: 'Selecione o produto',
                page: 't2'
            },
            '#t3': {
                icon_left: 'glyphicon glyphicon-menu-left',
                icon_right: 'glyphicon glyphicon-menu-right',
                back: () => this.voltarPasso2(),
                forward: () => $rootScope.finalizarEdicao(),
                forward_hide: () => !$rootScope.joiaSelecionada,
                title: '  Personalize',
                action_hide: () => this.desenhoPronto(),
                page: 't3'
            },
            '#t4': {
                back: null,
                back_hide: () => true,
                icon_right: 'glyphicon glyphicon-refresh',
                forward: () => this.confirmarReinicializacao(),
                title: 'Dados do pedido',
                page: 't4'
            }
        }
        const hash = location.hash || '#t1'
        this.filtroJoias = null
        this.filtroSimbolos = null
        $rootScope.joiaSelecionada = null
        this.pagina = Pagina.simbolos
        this.navigation = navconf[hash]

        $rootScope.enable_joias = false
        $rootScope.enable_simbolos = false
        $rootScope.enable_formulario = false

        var fontStyle = document.createElement("style");
        fontStyle.setAttribute("type", "text/css");
        fonts.forEach((font) => {
            const fontNode = document.createTextNode(font.link);
            fontStyle.appendChild(fontNode);
        });
        document.head.appendChild(fontStyle);

        this.adicionarFontesNoSVG = () => {
            $('#svgdrawer').attr('xmlns', 'http://www.w3.org/2000/svg')

            if (!$rootScope.isAnelOrBracelete()) {
                document.getElementById('svgdrawer').setAttribute('viewBox', '0 0 498 631')
            }

            var svgzera = document.getElementById('svgdrawer')
            var defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs')
            var style = document.createElementNS('http://www.w3.org/2000/svg', 'style')
            
            defs.appendChild(style)
            style.setAttribute('type', 'text/css')
            fonts.forEach((font) => {
              const fontNode = document.createTextNode(font.link);
              style.appendChild(fontNode);
            });
            svgzera.appendChild(defs)
        }

        $rootScope.isAnelOrBracelete = () => {
            return $rootScope.joiaSelecionada &&
                ($rootScope.joiaSelecionada.categoria === 'ANEIS' || $rootScope.joiaSelecionada.categoria === 'BRACELETES')
        }

        this.adicionarJoia = (joiaSelecionada) => {
            $rootScope.navegar('#t3')
            if (window.imageEditorFull) {
                window.imageEditorFull.clearScreen()
            }
            $rootScope.limparLayer()
            $rootScope.joiaSelecionada = joiaSelecionada
            if (!$rootScope.isAnelOrBracelete()) {
                this.carregarLayer()
                $scope.$apply();
            }
        }

        this.carregarLayer = () => {
            $rootScope.iniciarLoading();
            let parentHeight = $('#svgdrawer').height()
            parentHeight = parentHeight < 600 ? 642 : parentHeight
            let proporcao = (parentHeight / $rootScope.joiaSelecionada.height) - 0.15
            if (proporcao <= 0) proporcao = 0.55

            window.imageEditorFull.addLayer({
                'type': 'image',
                'src': $rootScope.joiaSelecionada.big,
                'x': 265,
                'y': 340,
                'width': $rootScope.joiaSelecionada.width * proporcao,
                'height': $rootScope.joiaSelecionada.height * proporcao,
                'isjoia': true,
                'isdark': $rootScope.joiaSelecionada.isdark
            })
            const svg = $('#svgdrawer')
            this.removerAparatos(svg)
            $('#svgdrawer').find('g').first().removeAttr('class').addClass('background-joia')
        }

        this.confirmarReinicializacao = () => {
            $rootScope.modalConfirmacao('Reiniciar pedido', 'Tem certeza que deseja reiniciar o pedido?', () => {
                $rootScope.joiaSelecionada = null
                window.imageEditorFull.clearScreen()
                $rootScope.limparLayer()
                $rootScope.restartFormReinicializacao()
                $rootScope.navegar('#t1')
                document.getElementById('svgdrawer').removeAttribute('viewBox')
                // window.location.reload(false);
            })
        }

        const dataURIToBlob = (dataURI) => {
			var binStr = atob(dataURI.split(',')[1]),
				len = binStr.length,
				arr = new Uint8Array(len)
			for (var i = 0; i < len; i++) {
				arr[i] = binStr.charCodeAt(i)
			}
			return new Blob([arr])
        }

        const forcarPretoNasLayers = (svgSemJoia) => {
            try {
                svgSemJoia.find('image').map((idx, el) => {
                    if (idx > 0) return
                    el.style.opacity = 1
                })
                svgSemJoia.find('text').map((idx, el) => el.setAttribute('fill', 'rgb(0,0,0)'))
                svgSemJoia.find('path').map((idx, el) => el.style.stroke = 'rgb(0,0,0)')
            } catch (error) {
                console.log('indexCtrl - Erro ao forçar preto nos layers', error);
            }
        }

        const montarSvgComJoia = () => {
            const svg = angular.copy(document.getElementById("svgdrawer").cloneNode(true))
            this.removerAparatos($(svg))
            const svgHtml = svg.outerHTML.replace(/\w+:href/g, 'href')
            return new Blob([svgHtml], {type: 'image/svg+xml'})
        }

        /*const montarsvgSemJoia = () => {
            const svgSemJoia = angular.copy(document.getElementById("svgdrawer").cloneNode(true))
            forcarPretoNasLayers($(svgSemJoia))
            $(svgSemJoia).find('.background-joia').remove()
			this.removerAparatos($(svgSemJoia))

            return svgAsPngUri(svgSemJoia, {}).then(uri => {
                return cropperPng(uri).then(cropped => dataURIToBlob(cropped))
            });
        }*/

        const armazenarMedidas = () => {
            const bbox = document.getElementById("svgdrawer").getBBox()
            $rootScope.svg_height = bbox.height
            $rootScope.svg_width = bbox.width
        }

        const montarSvgSemJoia = () => {
            const svg = angular.copy(document.getElementById("svgdrawer").cloneNode(true))
            forcarPretoNasLayers($(svg))
            $(svg).find('.background-joia').remove()
            this.removerAparatos($(svg))
            const svgHtml = svg.outerHTML.replace(/\w+:href/g, 'href')
            const blob = new Blob([svgHtml], {type: 'image/svg+xml'})
            return svgToPng(blob).then((png) => {
                return cropperPng(png).then(cropped => dataURIToBlob(cropped))
            })
        }

        const svgToPng = (svg) => {
            return new Promise((resolve, reject) => {
                var formData = new FormData()
                formData.append('file', svg)
                formData.append('width', $rootScope.svg_width)
                formData.append('height', $rootScope.svg_height)
                $.ajax({
                    type: 'POST',
					beforeSend: (request) => {
						request.setRequestHeader('access-token', window.localStorage.getItem('token'))
					},
					url: `${'@SERVER_URL@'}${'/api/v1/convert'}`,
					data: formData,
					cache: false,
					enctype: 'multipart/form-data',
					contentType: false,
					processData: false,
					success: (data) => resolve(data),
					error: (error) => reject(error)
                })
            })
        }

        this._handleError = (error) => {
            $rootScope.pararLoading()
            $rootScope.resetarForm()
            console.log('IndexCtrl - Erro ao gerar PNG', error)
            iziToast.show({ message: 'Não foi possível prosseguir', color: 'red' })
        }

        const armazenarEdicaoEProsseguir = () => {
            $rootScope.iniciarLoading()
            this.adicionarFontesNoSVG()

            armazenarMedidas()

            $timeout(function() {
                montarSvgSemJoia().then((png) => {
                    $rootScope.svgSemJoia = png
                    $rootScope.svgComJoia = montarSvgComJoia()
                    
                    $rootScope.pararLoading()
                    $rootScope.navegar('#t4')
                })
            })
            /*
            $timeout(function() {
                montarsvgSemJoia().then((svgSemJoia) => {
                    $rootScope.svgSemJoia = svgSemJoia

                    $timeout(function() {
                        //montarsvgComJoia().then((svgComJoia) => {
                            $rootScope.svgComJoia = montarSvgJoia();
                            // $rootScope.svgComJoia = svgComJoia
                            $rootScope.pararLoading()
                            $rootScope.navegar('#t4')
                        //})
                    }, 3000)
                })
            }, 2000)
            */
        }

        $rootScope.finalizarEdicao = () => {
            if (!this.possuiApenasJoia()) {
                $rootScope.modalConfirmacao('Concluir pedido', 'Tem certeza que deseja finalizar a edição?', () => {
                    armazenarEdicaoEProsseguir()
                })
            } else {
                iziToast.show({ message: 'Não foi encontrado nenhuma customização na jóia', color: 'red' })
            }
        }

        this.desenhoPronto = () => false

        $rootScope.modalConfirmacao = (titulo, message, onConfirm) => {
            var fClose = () => modal.modal('hide')
            var modal = $('#confirmModal')
            modal.modal('show')
            $('#confirmMessage').empty().append(message)
            $('#modalTitulo').empty().append(titulo)
            $('#confirmOk').unbind().one('click', onConfirm).one('click', fClose)
            $('#confirmCancel').unbind().one('click', fClose)
        }

        this.montarCategorias = () => {
            this.categorias = Object.assign([], baseProdutos)
            this.categorias.forEach((categoria) => {
                categoria.produtos.map((prod) => {
                    prod.src = `/images/joias/thumbs/${categoria.categoria.toLowerCase()}/${prod.nome}`
                    prod.big = `/images/joias/default/${categoria.categoria.toLowerCase()}/${prod.nome.replace('.png', '_g.png')}`
                    prod.isblack = !!prod.isblack
                })
            })
        }

        const toggle = (enablej, enables, enablef) => {
            $rootScope.enable_joias = enablej
            $rootScope.enable_simbolos = enables
            $rootScope.enable_formulario = enablef
        }

        $rootScope.navegar = (hash) => {
            switch (hash) {
                case '#t1':
                    toggle(false, false, false)
                    break
                case '#t2':
                    toggle(true, false, false)
                    break
                case '#t3':
                    toggle(false, true, false)
                    break
                case '#t4':
                    toggle(false, false, true)
                    break
            }

            location.href = hash
            this.navigation = navconf[hash]
        }

        this.possuiApenasJoia = () => {
            if (!$rootScope.isAnelOrBracelete()) {
                return $('svg').find('.background-joia').length === 1 && $('svg').children().length === 1
            } else {
                return $('svg').children().length === 0
            }
        }

        this.removerAparatos = (element) => {
            element.children().map((i, layers) => {
                if ($(layers).hasClass('background-joia') && i > 0) {
                    var content = document.getElementsByClassName('background-joia')[0];
                    var parent = content.parentNode;
                    parent.insertBefore(content, parent.firstChild);
                }

                $(layers).children().map((j, aparato) => {
                    if ($(aparato).hasClass('resizable-group') || $(aparato).hasClass('rotatable-group') ||
                        $(aparato).hasClass('remove-group') || $(aparato).hasClass('flip-group') ||
                        $(aparato).hasClass('draggable-handle')) {
                        aparato.remove()
                    }
                })
            })
        }

        this.voltarPasso2 = () => {
            if (!this.possuiApenasJoia()) {
                $rootScope.modalConfirmacao('Opa!', 'Tem certeza que deseja voltar? Se você confirmar, tudo que foi alterado até o momento será perdido.', () => {
                    $rootScope.joiaSelecionada = null
                    $rootScope.limparLayer()
                    $rootScope.navegar('#t2')
                })
            } else {
                $rootScope.navegar('#t2')
            }
        }

        $rootScope.iniciarLoading = () => $('#overlay').fadeIn()
        $rootScope.pararLoading = () => $('#overlay').fadeOut()

        this.montarCategorias()
        $rootScope.navegar('#t1')
    }
}