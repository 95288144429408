export default [
  {
    categoria: 'AMOR',
    simbolos: [
      {
        id: 'imagem217',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem218',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem219',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem220',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem294',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem221',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem222',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem223',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem224',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem225',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem226',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem227',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem228',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem229',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem230',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem231',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem232',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem233',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem234',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem235',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem236',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem237',
        nome: '22.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem238',
        nome: '23.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem240',
        nome: '24.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem241',
        nome: '25.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem242',
        nome: '26.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem243',
        nome: '27.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem244',
        nome: '28.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem245',
        nome: '29.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem246',
        nome: '30.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem247',
        nome: '31.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem248',
        nome: '32.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem249',
        nome: '33.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem250',
        nome: '34.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem251',
        nome: '35.svg',
        height: '200',
        width: 'auto'
      },
      // {
      //   id: 'imagem252',
      //   nome: '36.svg',
      //   height: '200',
      //   width: 'auto'
      // },
      {
        id: 'imagem253',
        nome: '38.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem254',
        nome: '39.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem255',
        nome: '40.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem256',
        nome: '41.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem257',
        nome: '42.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem258',
        nome: '43.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem259',
        nome: '44.svg',
        height: '200',
        width: 'auto'
      },
      // {
      //   id: 'imagem260',
      //   nome: '45.svg',
      //   height: '200',
      //   width: 'auto'
      // },
      {
        id: 'imagem261',
        nome: '46.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem262',
        nome: '47.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem263',
        nome: '48.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem264',
        nome: '49.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem265',
        nome: '50.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem266',
        nome: '51.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem267',
        nome: '52.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem268',
        nome: '53.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem269',
        nome: '54.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem270',
        nome: '56.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem271',
        nome: '57.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem272',
        nome: '58.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem273',
        nome: '59.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem274',
        nome: '60.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem275',
        nome: '61.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem276',
        nome: '62.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem277',
        nome: '63.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem278',
        nome: '64.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem279',
        nome: '65.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem280',
        nome: '66.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem281',
        nome: '67.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem282',
        nome: '68.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem283',
        nome: '69.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem284',
        nome: '70.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem285',
        nome: '71.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem286',
        nome: '72.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem287',
        nome: '73.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem288',
        nome: '74.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem289',
        nome: '75.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem290',
        nome: '76.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem291',
        nome: '77.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem292',
        nome: '78.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem293',
        nome: '79.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'BEACH',
    simbolos: [
      {
        id: 'imagem1',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem2',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem3',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem4',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem127',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem6',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem7',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem8',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem9',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem10',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem11',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem12',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem13',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem14',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem15',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem16',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem17',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem18',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem19',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'FITNESS',
    simbolos: [
      {
        id: 'imagem20',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem21',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem22',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem23',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem24',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem25',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem26',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem27',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem28',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem29',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem30',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem31',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem32',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem33',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem34',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem35',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem36',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem37',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem38',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem39',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem40',
        nome: '22.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem41',
        nome: '23.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem42',
        nome: '24.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem43',
        nome: '25.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem44',
        nome: '26.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem45',
        nome: '27.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem46',
        nome: '28.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem47',
        nome: '29.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem48',
        nome: '30.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem49',
        nome: '31.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem50',
        nome: '32.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem51',
        nome: '33.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem52',
        nome: '34.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem53',
        nome: '35.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem54',
        nome: '36.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem55',
        nome: '37.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem56',
        nome: '38.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem57',
        nome: '39.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem58',
        nome: '40.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem59',
        nome: '41.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem60',
        nome: '42.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem61',
        nome: '43.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem62',
        nome: '44.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'MUSICA',
    simbolos: [
      {
        id: 'imagem63',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem64',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem65',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem66',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem126',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem67',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem68',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'image69',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem70',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem71',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem72',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem73',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem74',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem75',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem76',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem77',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem78',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem79',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem80',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem81',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem82',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem83',
        nome: '22.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem84',
        nome: '23.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem85',
        nome: '24.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'PET',
    simbolos: [
      {
        id: 'imagem86',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'image87',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'image88',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem89',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem90',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem91',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem92',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem93',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem94',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem95',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem96',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem97',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem98',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem99',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem100',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem101',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'SIGNO',
    simbolos: [
      {
        id: 'imagem102',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem103',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem104',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem105',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem106',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem107',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem108',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem109',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem110',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem111',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem112',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem113',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem114',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem115',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem116',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem117',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem118',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem119',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem120',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem121',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem122',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem123',
        nome: '22.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem124',
        nome: '23.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem125',
        nome: '24.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'VIAGEM',
    simbolos: [
      {
        id: 'imagem128',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem129',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem130',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem131',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem132',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem133',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem134',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem135',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem136',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem137',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem138',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem139',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem140',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem141',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem142',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem143',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem144',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem145',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem146',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem147',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem148',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem149',
        nome: '22.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem150',
        nome: '23.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem151',
        nome: '24.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem152',
        nome: '25.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem153',
        nome: '26.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem154',
        nome: '27.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem155',
        nome: '28.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem156',
        nome: '29.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem157',
        nome: '30.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem158',
        nome: '31.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem159',
        nome: '32.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem160',
        nome: '33.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem161',
        nome: '34.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem162',
        nome: '35.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem163',
        nome: '36.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem164',
        nome: '37.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem165',
        nome: '38.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem166',
        nome: '39.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem167',
        nome: '40.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem168',
        nome: '41.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem169',
        nome: '42.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem170',
        nome: '43.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem171',
        nome: '44.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem172',
        nome: '45.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem173',
        nome: '46.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem174',
        nome: '47.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem175',
        nome: '48.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem176',
        nome: '49.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem177',
        nome: '50.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem178',
        nome: '51.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'JAPONES',
    simbolos: [
      {
        id: 'imagem179',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem180',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem181',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem182',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem216',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem183',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem184',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem185',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem186',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem187',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem188',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem189',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem190',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem191',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem192',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem193',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem194',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem195',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem196',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem197',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem198',
        nome: '22.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem199',
        nome: '23.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem200',
        nome: '25.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem201',
        nome: '26.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem202',
        nome: '27.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem203',
        nome: '28.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem204',
        nome: '29.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem205',
        nome: '30.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem206',
        nome: '31.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem207',
        nome: '32.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem208',
        nome: '33.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem209',
        nome: '34.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem210',
        nome: '35.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem211',
        nome: '36.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem212',
        nome: '38.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem213',
        nome: '39.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem214',
        nome: '40.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem215',
        nome: '41.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'SPACE',
    simbolos: [
      {
        id: 'imagem295',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem296',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem297',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem298',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem299',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem300',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem301',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem302',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem303',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem304',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem305',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem306',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem307',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem308',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem309',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem310',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem311',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem312',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem313',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem314',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem315',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem316',
        nome: '22.svg',
        height: '200',
        width: 'auto'
      }
    ]
  },
  {
    categoria: 'RELIGIAO',
    simbolos: [
      {
        id: 'imagem317',
        nome: '1.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem318',
        nome: '2.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem319',
        nome: '3.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem320',
        nome: '4.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem321',
        nome: '5.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem322',
        nome: '6.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem323',
        nome: '7.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem324',
        nome: '8.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem325',
        nome: '9.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem326',
        nome: '10.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem327',
        nome: '11.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem328',
        nome: '12.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem329',
        nome: '13.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem330',
        nome: '14.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem331',
        nome: '15.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem332',
        nome: '16.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem333',
        nome: '17.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem334',
        nome: '18.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem335',
        nome: '19.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem336',
        nome: '20.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem337',
        nome: '21.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem339',
        nome: '23.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem340',
        nome: '24.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem342',
        nome: '26.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem344',
        nome: '28.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem345',
        nome: '29.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem346',
        nome: '30.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem347',
        nome: '31.svg',
        height: '200',
        width: 'auto'
      },
      {
        id: 'imagem348',
        nome: '32.svg',
        height: '200',
        width: 'auto'
      }
    ]
  }
]
