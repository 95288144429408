import lojas from '../lojas'

export default class LoginCtrl {
	// @ngInject
	constructor($scope, $rootScope) {
		$rootScope.cidade = null
		$rootScope.pais = null
		this.lojas = lojas
		this.loja = null
		this.senha = null

		this.autenticacao = () => {
			if (!this.loja || !this.senha) {
				iziToast.show({ message: 'Campos obrigatórios não preenchidos', color: 'red' })
				return
			}

			$.ajax({
				type: 'POST',
				url: `${'@SERVER_URL@'}${'/api/v1/login'}`,
				data: {
					senha: this.senha //'seth2019@'
				},
				cache: false,
				success: (data) => {
					if (data && data.auth) {
						console.log('LoginCtrl - Autenticado com sucesso!')
						window.localStorage.setItem('token', data.token)
						window.localStorage.setItem('loja', JSON.stringify(this.loja))
						$('#loginModal').modal('hide')
					}
				},
				error: (error) => {
					iziToast.show({ message: 'Não foi possível autenticar', color: 'red' })
					console.log('LoginCtrl - Erro ao autenticar', error)
					$('#loginModal').modal('show')
				}
			})
		}

		this.verificarAutenticacao = () => {
			$.ajax({
				type: 'POST',
				url: `${'@SERVER_URL@'}${'/api/v1/validate/token'}`,
				cache: false,
				data: {
					token: window.localStorage.getItem('token')
				},
				success: (data) => {},
				error: (error) => {
					console.log('LoginCtrl - Erro ao autenticar', error)
					window.localStorage.removeItem('token')
					window.localStorage.removeItem('loja')
					$('#loginModal').modal('show')
				}
			})
		}

		$rootScope.geolocalizacao = () => {
            $.ajax({
                url: "http://ipinfo.io",
                jsonpCallback: "callback",
                dataType: "jsonp",
                success: (location) => {
                    $rootScope.cidade = location.city
                    $rootScope.pais = location.country_code
                }
			});
        }

		this.verificarAutenticacao()
		// $rootScope.geolocalizacao()

		this.availHeight = window.screen.availHeight
		this.availWidth = window.screen.availWidth
	}
}