(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

var _Pagina = require('./constants/Pagina');

var _Pagina2 = _interopRequireDefault(_Pagina);

var _GaleriaCtrl = require('./controllers/GaleriaCtrl');

var _GaleriaCtrl2 = _interopRequireDefault(_GaleriaCtrl);

var _IndexCtrl = require('./controllers/IndexCtrl');

var _IndexCtrl2 = _interopRequireDefault(_IndexCtrl);

var _LoginCtrl = require('./controllers/LoginCtrl');

var _LoginCtrl2 = _interopRequireDefault(_LoginCtrl);

var _SimbolosCtrl = require('./controllers/SimbolosCtrl');

var _SimbolosCtrl2 = _interopRequireDefault(_SimbolosCtrl);

var _FormularioCtrl = require('./controllers/FormularioCtrl');

var _FormularioCtrl2 = _interopRequireDefault(_FormularioCtrl);

var _cpf = require('./directives/cpf');

var _telefone = require('./directives/telefone');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var app = angular.module('app', []);

app.constant('Pagina', _Pagina2.default);
app.controller('LoginCtrl', _LoginCtrl2.default);
app.controller('GaleriaCtrl', _GaleriaCtrl2.default);
app.controller('IndexCtrl', _IndexCtrl2.default);
app.controller('SimbolosCtrl', _SimbolosCtrl2.default);
app.controller('FormularioCtrl', _FormularioCtrl2.default);
app.directive('cpf', _cpf.CPFMask);
app.directive('telefone', _telefone.TelefoneMask);

app.config(['$locationProvider', function ($locationProvider) {
    $locationProvider.html5Mode({ enabled: true, requireBase: false }).hashPrefix('!');
}]);

},{"./constants/Pagina":4,"./controllers/FormularioCtrl":5,"./controllers/GaleriaCtrl":6,"./controllers/IndexCtrl":7,"./controllers/LoginCtrl":8,"./controllers/SimbolosCtrl":9,"./directives/cpf":10,"./directives/telefone":11}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = [{
  categoria: 'ANEIS',
  produtos: [{
    id: 'imagem77',
    nome: '2.png',
    height: '1391',
    width: '600',
    categoria: 'ANEIS'
  }, {
    id: 'imagem78',
    nome: '3.png',
    height: '1385',
    width: '600',
    categoria: 'ANEIS'
  }]
}, {
  categoria: 'BRACELETES',
  produtos: [{
    id: 'imagem76',
    nome: '1.png',
    height: '1391',
    width: '600',
    categoria: 'BRACELETES'
  }, {
    id: 'imagem79',
    nome: '4.png',
    height: '1386',
    width: '600',
    categoria: 'BRACELETES'
  }]
}, {
  categoria: 'PINGENTES',
  produtos: [{
    id: 'imagem24',
    nome: '1.png',
    height: '888',
    width: '572',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem25',
    nome: '2.png',
    height: '894',
    width: '705',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem26',
    nome: '3.png',
    height: '708',
    width: '552',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem27',
    nome: '4.png',
    height: '990',
    width: '732',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem29',
    nome: '5.png',
    height: '984',
    width: '840',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem30',
    nome: '6.png',
    height: '984',
    width: '768',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem31',
    nome: '7.png',
    height: '1200',
    width: '492',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem32',
    nome: '8.png',
    height: '800',
    width: '590',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem32',
    nome: '9.png',
    height: '1000',
    width: '708',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem32',
    nome: '10.png',
    height: '900',
    width: '384',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem32',
    nome: '11.png',
    height: '908',
    width: '252',
    categoria: 'PINGENTES'
  }, {
    id: 'imagem32',
    nome: '12.png',
    height: '708',
    width: '552',
    categoria: 'PINGENTES'
  }]
}, {
  categoria: 'PULSEIRAS',
  produtos: [{
    id: 'imagem33',
    nome: '1.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem34',
    nome: '2.png',
    height: '984',
    width: '800',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem35',
    nome: '3.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem36',
    nome: '4.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem37',
    nome: '5.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem38',
    nome: '6.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem39',
    nome: '7.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem40',
    nome: '8.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem41',
    nome: '9.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem42',
    nome: '10.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem43',
    nome: '11.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem44',
    nome: '12.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem45',
    nome: '13.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem46',
    nome: '14.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem47',
    nome: '15.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem48',
    nome: '16.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem49',
    nome: '17.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem50',
    nome: '18.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem51',
    nome: '19.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem52',
    nome: '20.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem53',
    nome: '21.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem54',
    nome: '22.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem55',
    nome: '23.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem56',
    nome: '24.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem57',
    nome: '25.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem58',
    nome: '26.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem59',
    nome: '27.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem60',
    nome: '28.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem61',
    nome: '29.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem62',
    nome: '30.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem63',
    nome: '31.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem64',
    nome: '32.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem65',
    nome: '33.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem66',
    nome: '34.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem67',
    nome: '35.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem68',
    nome: '36.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem69',
    nome: '37.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem70',
    nome: '38.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem71',
    nome: '39.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem72',
    nome: '40.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem73',
    nome: '41.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem74',
    nome: '42.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem75',
    nome: '43.png',
    height: '984',
    width: '840',
    isdark: true,
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem79',
    nome: '44.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem80',
    nome: '45.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem81',
    nome: '46.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem82',
    nome: '47.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem83',
    nome: '48.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem84',
    nome: '49.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem85',
    nome: '50.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem86',
    nome: '51.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem87',
    nome: '52.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem88',
    nome: '53.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem89',
    nome: '54.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem90',
    nome: '55.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem91',
    nome: '56.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem92',
    nome: '57.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem93',
    nome: '58.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }, {
    id: 'imagem94',
    nome: '59.png',
    height: '984',
    width: '840',
    categoria: 'PULSEIRAS'
  }]
}];

},{}],3:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = [{
  categoria: 'AMOR',
  simbolos: [{
    id: 'imagem217',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem218',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem219',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem220',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem294',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem221',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem222',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem223',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem224',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem225',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem226',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem227',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem228',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem229',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem230',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem231',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem232',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem233',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem234',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem235',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem236',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem237',
    nome: '22.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem238',
    nome: '23.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem240',
    nome: '24.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem241',
    nome: '25.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem242',
    nome: '26.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem243',
    nome: '27.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem244',
    nome: '28.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem245',
    nome: '29.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem246',
    nome: '30.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem247',
    nome: '31.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem248',
    nome: '32.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem249',
    nome: '33.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem250',
    nome: '34.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem251',
    nome: '35.svg',
    height: '200',
    width: 'auto'
  },
  // {
  //   id: 'imagem252',
  //   nome: '36.svg',
  //   height: '200',
  //   width: 'auto'
  // },
  {
    id: 'imagem253',
    nome: '38.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem254',
    nome: '39.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem255',
    nome: '40.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem256',
    nome: '41.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem257',
    nome: '42.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem258',
    nome: '43.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem259',
    nome: '44.svg',
    height: '200',
    width: 'auto'
  },
  // {
  //   id: 'imagem260',
  //   nome: '45.svg',
  //   height: '200',
  //   width: 'auto'
  // },
  {
    id: 'imagem261',
    nome: '46.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem262',
    nome: '47.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem263',
    nome: '48.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem264',
    nome: '49.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem265',
    nome: '50.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem266',
    nome: '51.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem267',
    nome: '52.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem268',
    nome: '53.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem269',
    nome: '54.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem270',
    nome: '56.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem271',
    nome: '57.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem272',
    nome: '58.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem273',
    nome: '59.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem274',
    nome: '60.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem275',
    nome: '61.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem276',
    nome: '62.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem277',
    nome: '63.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem278',
    nome: '64.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem279',
    nome: '65.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem280',
    nome: '66.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem281',
    nome: '67.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem282',
    nome: '68.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem283',
    nome: '69.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem284',
    nome: '70.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem285',
    nome: '71.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem286',
    nome: '72.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem287',
    nome: '73.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem288',
    nome: '74.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem289',
    nome: '75.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem290',
    nome: '76.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem291',
    nome: '77.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem292',
    nome: '78.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem293',
    nome: '79.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'BEACH',
  simbolos: [{
    id: 'imagem1',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem2',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem3',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem4',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem127',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem6',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem7',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem8',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem9',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem10',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem11',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem12',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem13',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem14',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem15',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem16',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem17',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem18',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem19',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'FITNESS',
  simbolos: [{
    id: 'imagem20',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem21',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem22',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem23',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem24',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem25',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem26',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem27',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem28',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem29',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem30',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem31',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem32',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem33',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem34',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem35',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem36',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem37',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem38',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem39',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem40',
    nome: '22.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem41',
    nome: '23.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem42',
    nome: '24.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem43',
    nome: '25.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem44',
    nome: '26.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem45',
    nome: '27.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem46',
    nome: '28.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem47',
    nome: '29.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem48',
    nome: '30.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem49',
    nome: '31.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem50',
    nome: '32.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem51',
    nome: '33.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem52',
    nome: '34.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem53',
    nome: '35.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem54',
    nome: '36.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem55',
    nome: '37.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem56',
    nome: '38.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem57',
    nome: '39.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem58',
    nome: '40.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem59',
    nome: '41.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem60',
    nome: '42.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem61',
    nome: '43.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem62',
    nome: '44.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'MUSICA',
  simbolos: [{
    id: 'imagem63',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem64',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem65',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem66',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem126',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem67',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem68',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'image69',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem70',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem71',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem72',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem73',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem74',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem75',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem76',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem77',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem78',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem79',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem80',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem81',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem82',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem83',
    nome: '22.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem84',
    nome: '23.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem85',
    nome: '24.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'PET',
  simbolos: [{
    id: 'imagem86',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'image87',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'image88',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem89',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem90',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem91',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem92',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem93',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem94',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem95',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem96',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem97',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem98',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem99',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem100',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem101',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'SIGNO',
  simbolos: [{
    id: 'imagem102',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem103',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem104',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem105',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem106',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem107',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem108',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem109',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem110',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem111',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem112',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem113',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem114',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem115',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem116',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem117',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem118',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem119',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem120',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem121',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem122',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem123',
    nome: '22.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem124',
    nome: '23.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem125',
    nome: '24.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'VIAGEM',
  simbolos: [{
    id: 'imagem128',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem129',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem130',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem131',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem132',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem133',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem134',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem135',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem136',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem137',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem138',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem139',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem140',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem141',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem142',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem143',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem144',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem145',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem146',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem147',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem148',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem149',
    nome: '22.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem150',
    nome: '23.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem151',
    nome: '24.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem152',
    nome: '25.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem153',
    nome: '26.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem154',
    nome: '27.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem155',
    nome: '28.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem156',
    nome: '29.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem157',
    nome: '30.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem158',
    nome: '31.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem159',
    nome: '32.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem160',
    nome: '33.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem161',
    nome: '34.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem162',
    nome: '35.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem163',
    nome: '36.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem164',
    nome: '37.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem165',
    nome: '38.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem166',
    nome: '39.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem167',
    nome: '40.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem168',
    nome: '41.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem169',
    nome: '42.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem170',
    nome: '43.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem171',
    nome: '44.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem172',
    nome: '45.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem173',
    nome: '46.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem174',
    nome: '47.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem175',
    nome: '48.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem176',
    nome: '49.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem177',
    nome: '50.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem178',
    nome: '51.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'JAPONES',
  simbolos: [{
    id: 'imagem179',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem180',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem181',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem182',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem216',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem183',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem184',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem185',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem186',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem187',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem188',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem189',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem190',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem191',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem192',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem193',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem194',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem195',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem196',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem197',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem198',
    nome: '22.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem199',
    nome: '23.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem200',
    nome: '25.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem201',
    nome: '26.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem202',
    nome: '27.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem203',
    nome: '28.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem204',
    nome: '29.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem205',
    nome: '30.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem206',
    nome: '31.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem207',
    nome: '32.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem208',
    nome: '33.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem209',
    nome: '34.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem210',
    nome: '35.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem211',
    nome: '36.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem212',
    nome: '38.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem213',
    nome: '39.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem214',
    nome: '40.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem215',
    nome: '41.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'SPACE',
  simbolos: [{
    id: 'imagem295',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem296',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem297',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem298',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem299',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem300',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem301',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem302',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem303',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem304',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem305',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem306',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem307',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem308',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem309',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem310',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem311',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem312',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem313',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem314',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem315',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem316',
    nome: '22.svg',
    height: '200',
    width: 'auto'
  }]
}, {
  categoria: 'RELIGIAO',
  simbolos: [{
    id: 'imagem317',
    nome: '1.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem318',
    nome: '2.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem319',
    nome: '3.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem320',
    nome: '4.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem321',
    nome: '5.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem322',
    nome: '6.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem323',
    nome: '7.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem324',
    nome: '8.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem325',
    nome: '9.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem326',
    nome: '10.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem327',
    nome: '11.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem328',
    nome: '12.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem329',
    nome: '13.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem330',
    nome: '14.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem331',
    nome: '15.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem332',
    nome: '16.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem333',
    nome: '17.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem334',
    nome: '18.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem335',
    nome: '19.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem336',
    nome: '20.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem337',
    nome: '21.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem339',
    nome: '23.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem340',
    nome: '24.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem342',
    nome: '26.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem344',
    nome: '28.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem345',
    nome: '29.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem346',
    nome: '30.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem347',
    nome: '31.svg',
    height: '200',
    width: 'auto'
  }, {
    id: 'imagem348',
    nome: '32.svg',
    height: '200',
    width: 'auto'
  }]
}];

},{}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var Pagina = {
    login: 'LOGIN',
    simbolos: 'SIMBOLOS',
    textos: 'TEXTOS'
};
exports.default = Pagina;

},{}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LoginCtrl =
// @ngInject
function LoginCtrl($scope, $rootScope) {
	var _this = this;

	_classCallCheck(this, LoginCtrl);

	this.cidade = null;
	this.pais = null;

	var enviarEmail = function enviarEmail(svgBlob1, svgBlob2, svg_width, svg_height) {
		return new Promise(function (resolve, reject) {
			var dadosLoja = JSON.parse(window.localStorage.getItem('loja'));
			var formData = new FormData();
			formData.append('file', svgBlob1);
			formData.append('file', svgBlob2);
			formData.append('svg_width', svg_width);
			formData.append('svg_height', svg_height);
			formData.append('cliente', JSON.stringify($rootScope.form));
			formData.append('loja', dadosLoja.loja);
			formData.append('email-loja', dadosLoja.email);

			$.ajax({
				type: 'POST',
				beforeSend: function beforeSend(request) {
					request.setRequestHeader('access-token', window.localStorage.getItem('token'));
				},
				url: '@SERVER_URL@' + '/api/v1/email/send',
				data: formData,
				cache: false,
				enctype: 'multipart/form-data',
				contentType: false,
				processData: false,
				success: function success(data) {
					return resolve(data);
				},
				error: function error(_error) {
					return reject(_error);
				}
			});
		});
	};

	var finalizar = function finalizar() {
		$rootScope.iniciarLoading();
		var svgComJoia = $rootScope.svgComJoia,
		    svgSemJoia = $rootScope.svgSemJoia,
		    svg_width = $rootScope.svg_width,
		    svg_height = $rootScope.svg_height;

		enviarEmail(svgComJoia, svgSemJoia, svg_width, svg_height).then(function (success) {
			$rootScope.pararLoading();
			console.log('FormularioCtrl - Pedido finalizado com sucesso', success);
			$rootScope.joiaSelecionada = null;
			window.imageEditorFull.clearScreen();
			$rootScope.limparLayer();
			$rootScope.resetarForm();
			$rootScope.navegar('#t1');
			document.getElementById('svgdrawer').removeAttribute('viewBox');
			iziToast.show({
				message: 'Pedido finalizado com sucesso',
				color: 'green'
			});

			// window.setTimeout(() => {
			// 	window.location.reload(false);
			// }, 2000);
		}).catch(function (error) {
			return _this._handleError(error);
		});
	};

	this._handleError = function (error) {
		$rootScope.pararLoading();
		$rootScope.resetarForm();
		console.log('FormularioCtrl - Erro ao enviar email', error);
		iziToast.show({ message: 'Não foi possível finalizar o pedido', color: 'red' });
	};

	this.iniciarForm = function () {
		var theForm = document.getElementById('theForm');
		theForm.setAttribute('autocomplete', 'off');
		new stepsForm(theForm, {
			onSubmit: function onSubmit(form) {
				$rootScope.form = {
					nome: $('#nome').val(),
					cpf: $('#cpf').val(),
					telefone: $('#telefone').val(),
					email: $('#email').val()
				};
				window.classie.addClass(theForm.querySelector('.simform-inner'), 'hide');
				var messageEl = theForm.querySelector('.final-message');
				window.classie.addClass(messageEl, 'show');
				finalizar();
			}
		});
		$rootScope.form = {};
	};

	$rootScope.resetarForm = function () {
		var theForm = document.getElementById('theForm');
		theForm.reset();
		window.classie.addClass(theForm.querySelector('.simform-inner'), 'show');
		window.classie.removeClass(theForm.querySelector('.simform-inner'), 'hide');
		window.classie.addClass(theForm.querySelector('.final-message'), 'hide');
		window.classie.removeClass(theForm.querySelector('.final-message'), 'show');
		window.classie.removeClass(theForm.querySelector('.error-message'), 'show');
		$rootScope.joiaSelecionada = null;
		$('.questions li:nth-child(2)').removeClass('current');
		$('.questions li:nth-child(3)').removeClass('current');
		$('.questions li:nth-child(4)').removeClass('current');
		_this.iniciarForm();
	};

	$rootScope.restartFormReinicializacao = function () {
		var theForm = document.getElementById('theForm');
		theForm.reset();
		window.classie.addClass(theForm.querySelector('.simform-inner'), 'show');
		window.classie.removeClass(theForm.querySelector('.simform-inner'), 'hide');
		window.classie.addClass(theForm.querySelector('.final-message'), 'hide');
		window.classie.removeClass(theForm.querySelector('.final-message'), 'show');
		window.classie.removeClass(theForm.querySelector('.error-message'), 'show');
		$rootScope.joiaSelecionada = null;
		$('.questions li:nth-child(2)').removeClass('current');
		$('.questions li:nth-child(3)').removeClass('current');
		$('.questions li:nth-child(4)').removeClass('current');
		theForm.setAttribute('autocomplete', 'off');
		new stepsForm(theForm);
	};

	this.iniciarForm();
};

exports.default = LoginCtrl;

},{}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var GaleriaCtrl =

/*@ngInject;*/
function GaleriaCtrl() {
	_classCallCheck(this, GaleriaCtrl);

	this.name = 'person';
};

exports.default = GaleriaCtrl;

},{}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _base_produtos = require('../base_produtos');

var _base_produtos2 = _interopRequireDefault(_base_produtos);

var _fonts = require('../fonts');

var _fonts2 = _interopRequireDefault(_fonts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var IndexCtrl =
// @ngInject
function IndexCtrl(Pagina, $rootScope, $scope, $timeout) {
    var _this = this;

    _classCallCheck(this, IndexCtrl);

    var navconf = {
        '#t1': {
            back: null,
            back_hide: function back_hide() {
                return true;
            },
            forward: function forward() {
                return $rootScope.navegar('#t2');
            },
            forward_hide: function forward_hide() {
                return true;
            },
            page: 't1'
        },
        '#t2': {
            icon_left: 'glyphicon glyphicon-log-out flip-horizontal',
            icon_right: 'glyphicon glyphicon-menu-right',
            back: function back() {
                return $rootScope.navegar('#t1');
            },
            forward: function forward() {
                return $rootScope.navegar('#t3');
            },
            forward_hide: function forward_hide() {
                return !$rootScope.joiaSelecionada;
            },
            title: 'Selecione o produto',
            page: 't2'
        },
        '#t3': {
            icon_left: 'glyphicon glyphicon-menu-left',
            icon_right: 'glyphicon glyphicon-menu-right',
            back: function back() {
                return _this.voltarPasso2();
            },
            forward: function forward() {
                return $rootScope.finalizarEdicao();
            },
            forward_hide: function forward_hide() {
                return !$rootScope.joiaSelecionada;
            },
            title: '  Personalize',
            action_hide: function action_hide() {
                return _this.desenhoPronto();
            },
            page: 't3'
        },
        '#t4': {
            back: null,
            back_hide: function back_hide() {
                return true;
            },
            icon_right: 'glyphicon glyphicon-refresh',
            forward: function forward() {
                return _this.confirmarReinicializacao();
            },
            title: 'Dados do pedido',
            page: 't4'
        }
    };
    var hash = location.hash || '#t1';
    this.filtroJoias = null;
    this.filtroSimbolos = null;
    $rootScope.joiaSelecionada = null;
    this.pagina = Pagina.simbolos;
    this.navigation = navconf[hash];

    $rootScope.enable_joias = false;
    $rootScope.enable_simbolos = false;
    $rootScope.enable_formulario = false;

    var fontStyle = document.createElement("style");
    fontStyle.setAttribute("type", "text/css");
    _fonts2.default.forEach(function (font) {
        var fontNode = document.createTextNode(font.link);
        fontStyle.appendChild(fontNode);
    });
    document.head.appendChild(fontStyle);

    this.adicionarFontesNoSVG = function () {
        $('#svgdrawer').attr('xmlns', 'http://www.w3.org/2000/svg');

        if (!$rootScope.isAnelOrBracelete()) {
            document.getElementById('svgdrawer').setAttribute('viewBox', '0 0 498 631');
        }

        var svgzera = document.getElementById('svgdrawer');
        var defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
        var style = document.createElementNS('http://www.w3.org/2000/svg', 'style');

        defs.appendChild(style);
        style.setAttribute('type', 'text/css');
        _fonts2.default.forEach(function (font) {
            var fontNode = document.createTextNode(font.link);
            style.appendChild(fontNode);
        });
        svgzera.appendChild(defs);
    };

    $rootScope.isAnelOrBracelete = function () {
        return $rootScope.joiaSelecionada && ($rootScope.joiaSelecionada.categoria === 'ANEIS' || $rootScope.joiaSelecionada.categoria === 'BRACELETES');
    };

    this.adicionarJoia = function (joiaSelecionada) {
        $rootScope.navegar('#t3');
        if (window.imageEditorFull) {
            window.imageEditorFull.clearScreen();
        }
        $rootScope.limparLayer();
        $rootScope.joiaSelecionada = joiaSelecionada;
        if (!$rootScope.isAnelOrBracelete()) {
            _this.carregarLayer();
            $scope.$apply();
        }
    };

    this.carregarLayer = function () {
        $rootScope.iniciarLoading();
        var parentHeight = $('#svgdrawer').height();
        parentHeight = parentHeight < 600 ? 642 : parentHeight;
        var proporcao = parentHeight / $rootScope.joiaSelecionada.height - 0.15;
        if (proporcao <= 0) proporcao = 0.55;

        window.imageEditorFull.addLayer({
            'type': 'image',
            'src': $rootScope.joiaSelecionada.big,
            'x': 265,
            'y': 340,
            'width': $rootScope.joiaSelecionada.width * proporcao,
            'height': $rootScope.joiaSelecionada.height * proporcao,
            'isjoia': true,
            'isdark': $rootScope.joiaSelecionada.isdark
        });
        var svg = $('#svgdrawer');
        _this.removerAparatos(svg);
        $('#svgdrawer').find('g').first().removeAttr('class').addClass('background-joia');
    };

    this.confirmarReinicializacao = function () {
        $rootScope.modalConfirmacao('Reiniciar pedido', 'Tem certeza que deseja reiniciar o pedido?', function () {
            $rootScope.joiaSelecionada = null;
            window.imageEditorFull.clearScreen();
            $rootScope.limparLayer();
            $rootScope.restartFormReinicializacao();
            $rootScope.navegar('#t1');
            document.getElementById('svgdrawer').removeAttribute('viewBox');
            // window.location.reload(false);
        });
    };

    var dataURIToBlob = function dataURIToBlob(dataURI) {
        var binStr = atob(dataURI.split(',')[1]),
            len = binStr.length,
            arr = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }
        return new Blob([arr]);
    };

    var forcarPretoNasLayers = function forcarPretoNasLayers(svgSemJoia) {
        try {
            svgSemJoia.find('image').map(function (idx, el) {
                if (idx > 0) return;
                el.style.opacity = 1;
            });
            svgSemJoia.find('text').map(function (idx, el) {
                return el.setAttribute('fill', 'rgb(0,0,0)');
            });
            svgSemJoia.find('path').map(function (idx, el) {
                return el.style.stroke = 'rgb(0,0,0)';
            });
        } catch (error) {
            console.log('indexCtrl - Erro ao forçar preto nos layers', error);
        }
    };

    var montarSvgComJoia = function montarSvgComJoia() {
        var svg = angular.copy(document.getElementById("svgdrawer").cloneNode(true));
        _this.removerAparatos($(svg));
        var svgHtml = svg.outerHTML.replace(/\w+:href/g, 'href');
        return new Blob([svgHtml], { type: 'image/svg+xml' });
    };

    /*const montarsvgSemJoia = () => {
        const svgSemJoia = angular.copy(document.getElementById("svgdrawer").cloneNode(true))
        forcarPretoNasLayers($(svgSemJoia))
        $(svgSemJoia).find('.background-joia').remove()
    this.removerAparatos($(svgSemJoia))
         return svgAsPngUri(svgSemJoia, {}).then(uri => {
            return cropperPng(uri).then(cropped => dataURIToBlob(cropped))
        });
    }*/

    var armazenarMedidas = function armazenarMedidas() {
        var bbox = document.getElementById("svgdrawer").getBBox();
        $rootScope.svg_height = bbox.height;
        $rootScope.svg_width = bbox.width;
    };

    var montarSvgSemJoia = function montarSvgSemJoia() {
        var svg = angular.copy(document.getElementById("svgdrawer").cloneNode(true));
        forcarPretoNasLayers($(svg));
        $(svg).find('.background-joia').remove();
        _this.removerAparatos($(svg));
        var svgHtml = svg.outerHTML.replace(/\w+:href/g, 'href');
        var blob = new Blob([svgHtml], { type: 'image/svg+xml' });
        return svgToPng(blob).then(function (png) {
            return cropperPng(png).then(function (cropped) {
                return dataURIToBlob(cropped);
            });
        });
    };

    var svgToPng = function svgToPng(svg) {
        return new Promise(function (resolve, reject) {
            var formData = new FormData();
            formData.append('file', svg);
            formData.append('width', $rootScope.svg_width);
            formData.append('height', $rootScope.svg_height);
            $.ajax({
                type: 'POST',
                beforeSend: function beforeSend(request) {
                    request.setRequestHeader('access-token', window.localStorage.getItem('token'));
                },
                url: '@SERVER_URL@' + '/api/v1/convert',
                data: formData,
                cache: false,
                enctype: 'multipart/form-data',
                contentType: false,
                processData: false,
                success: function success(data) {
                    return resolve(data);
                },
                error: function error(_error) {
                    return reject(_error);
                }
            });
        });
    };

    this._handleError = function (error) {
        $rootScope.pararLoading();
        $rootScope.resetarForm();
        console.log('IndexCtrl - Erro ao gerar PNG', error);
        iziToast.show({ message: 'Não foi possível prosseguir', color: 'red' });
    };

    var armazenarEdicaoEProsseguir = function armazenarEdicaoEProsseguir() {
        $rootScope.iniciarLoading();
        _this.adicionarFontesNoSVG();

        armazenarMedidas();

        $timeout(function () {
            montarSvgSemJoia().then(function (png) {
                $rootScope.svgSemJoia = png;
                $rootScope.svgComJoia = montarSvgComJoia();

                $rootScope.pararLoading();
                $rootScope.navegar('#t4');
            });
        });
        /*
        $timeout(function() {
            montarsvgSemJoia().then((svgSemJoia) => {
                $rootScope.svgSemJoia = svgSemJoia
                 $timeout(function() {
                    //montarsvgComJoia().then((svgComJoia) => {
                        $rootScope.svgComJoia = montarSvgJoia();
                        // $rootScope.svgComJoia = svgComJoia
                        $rootScope.pararLoading()
                        $rootScope.navegar('#t4')
                    //})
                }, 3000)
            })
        }, 2000)
        */
    };

    $rootScope.finalizarEdicao = function () {
        if (!_this.possuiApenasJoia()) {
            $rootScope.modalConfirmacao('Concluir pedido', 'Tem certeza que deseja finalizar a edição?', function () {
                armazenarEdicaoEProsseguir();
            });
        } else {
            iziToast.show({ message: 'Não foi encontrado nenhuma customização na jóia', color: 'red' });
        }
    };

    this.desenhoPronto = function () {
        return false;
    };

    $rootScope.modalConfirmacao = function (titulo, message, onConfirm) {
        var fClose = function fClose() {
            return modal.modal('hide');
        };
        var modal = $('#confirmModal');
        modal.modal('show');
        $('#confirmMessage').empty().append(message);
        $('#modalTitulo').empty().append(titulo);
        $('#confirmOk').unbind().one('click', onConfirm).one('click', fClose);
        $('#confirmCancel').unbind().one('click', fClose);
    };

    this.montarCategorias = function () {
        _this.categorias = Object.assign([], _base_produtos2.default);
        _this.categorias.forEach(function (categoria) {
            categoria.produtos.map(function (prod) {
                prod.src = '/images/joias/thumbs/' + categoria.categoria.toLowerCase() + '/' + prod.nome;
                prod.big = '/images/joias/default/' + categoria.categoria.toLowerCase() + '/' + prod.nome.replace('.png', '_g.png');
                prod.isblack = !!prod.isblack;
            });
        });
    };

    var toggle = function toggle(enablej, enables, enablef) {
        $rootScope.enable_joias = enablej;
        $rootScope.enable_simbolos = enables;
        $rootScope.enable_formulario = enablef;
    };

    $rootScope.navegar = function (hash) {
        switch (hash) {
            case '#t1':
                toggle(false, false, false);
                break;
            case '#t2':
                toggle(true, false, false);
                break;
            case '#t3':
                toggle(false, true, false);
                break;
            case '#t4':
                toggle(false, false, true);
                break;
        }

        location.href = hash;
        _this.navigation = navconf[hash];
    };

    this.possuiApenasJoia = function () {
        if (!$rootScope.isAnelOrBracelete()) {
            return $('svg').find('.background-joia').length === 1 && $('svg').children().length === 1;
        } else {
            return $('svg').children().length === 0;
        }
    };

    this.removerAparatos = function (element) {
        element.children().map(function (i, layers) {
            if ($(layers).hasClass('background-joia') && i > 0) {
                var content = document.getElementsByClassName('background-joia')[0];
                var parent = content.parentNode;
                parent.insertBefore(content, parent.firstChild);
            }

            $(layers).children().map(function (j, aparato) {
                if ($(aparato).hasClass('resizable-group') || $(aparato).hasClass('rotatable-group') || $(aparato).hasClass('remove-group') || $(aparato).hasClass('flip-group') || $(aparato).hasClass('draggable-handle')) {
                    aparato.remove();
                }
            });
        });
    };

    this.voltarPasso2 = function () {
        if (!_this.possuiApenasJoia()) {
            $rootScope.modalConfirmacao('Opa!', 'Tem certeza que deseja voltar? Se você confirmar, tudo que foi alterado até o momento será perdido.', function () {
                $rootScope.joiaSelecionada = null;
                $rootScope.limparLayer();
                $rootScope.navegar('#t2');
            });
        } else {
            $rootScope.navegar('#t2');
        }
    };

    $rootScope.iniciarLoading = function () {
        return $('#overlay').fadeIn();
    };
    $rootScope.pararLoading = function () {
        return $('#overlay').fadeOut();
    };

    this.montarCategorias();
    $rootScope.navegar('#t1');
};

exports.default = IndexCtrl;

},{"../base_produtos":2,"../fonts":12}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _lojas = require('../lojas');

var _lojas2 = _interopRequireDefault(_lojas);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var LoginCtrl =
// @ngInject
function LoginCtrl($scope, $rootScope) {
	var _this = this;

	_classCallCheck(this, LoginCtrl);

	$rootScope.cidade = null;
	$rootScope.pais = null;
	this.lojas = _lojas2.default;
	this.loja = null;
	this.senha = null;

	this.autenticacao = function () {
		if (!_this.loja || !_this.senha) {
			iziToast.show({ message: 'Campos obrigatórios não preenchidos', color: 'red' });
			return;
		}

		$.ajax({
			type: 'POST',
			url: '@SERVER_URL@' + '/api/v1/login',
			data: {
				senha: _this.senha //'seth2019@'
			},
			cache: false,
			success: function success(data) {
				if (data && data.auth) {
					console.log('LoginCtrl - Autenticado com sucesso!');
					window.localStorage.setItem('token', data.token);
					window.localStorage.setItem('loja', JSON.stringify(_this.loja));
					$('#loginModal').modal('hide');
				}
			},
			error: function error(_error) {
				iziToast.show({ message: 'Não foi possível autenticar', color: 'red' });
				console.log('LoginCtrl - Erro ao autenticar', _error);
				$('#loginModal').modal('show');
			}
		});
	};

	this.verificarAutenticacao = function () {
		$.ajax({
			type: 'POST',
			url: '@SERVER_URL@' + '/api/v1/validate/token',
			cache: false,
			data: {
				token: window.localStorage.getItem('token')
			},
			success: function success(data) {},
			error: function error(_error2) {
				console.log('LoginCtrl - Erro ao autenticar', _error2);
				window.localStorage.removeItem('token');
				window.localStorage.removeItem('loja');
				$('#loginModal').modal('show');
			}
		});
	};

	$rootScope.geolocalizacao = function () {
		$.ajax({
			url: "http://ipinfo.io",
			jsonpCallback: "callback",
			dataType: "jsonp",
			success: function success(location) {
				$rootScope.cidade = location.city;
				$rootScope.pais = location.country_code;
			}
		});
	};

	this.verificarAutenticacao();
	// $rootScope.geolocalizacao()

	this.availHeight = window.screen.availHeight;
	this.availWidth = window.screen.availWidth;
};

exports.default = LoginCtrl;

},{"../lojas":13}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _base_simbolos = require("../base_simbolos");

var _base_simbolos2 = _interopRequireDefault(_base_simbolos);

var _fonts = require("../fonts");

var _fonts2 = _interopRequireDefault(_fonts);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var SimbolosCtrl =
// @ngInject
function SimbolosCtrl(Pagina, $rootScope, $scope) {
  var _this = this;

  _classCallCheck(this, SimbolosCtrl);

  this.pagina = Pagina.simbolos;
  this.desenhoHabilitado = false;
  this.abaSelecionada = null;
  this.svg = document.getElementById("svgdrawer");
  this.larguraLinha = "4.0";
  this.fonts = _fonts2.default.map(function (font) {
    return font.name;
  });
  this.fonte = "Times New Roman";
  this.layerSelecionada = null;
  this.imageEditorWidth = $("#imageEditor").width();

  var data = [],
      path,
      showZooom = false;

  this.$onInit = function () {
    $("#fontSelect").select2({
      data: _fonts2.default.map(function (font) {
        var html = "<div style=\"color: black; font-family: " + font.name + "\">" + font.name + "</div>";
        var htmlSelected = "<div style=\"color: black; font-family: " + font.name + "; font-size: 24px\">" + font.name + "</div>";
        return { id: font.name, text: font.name, html: html, htmlSelected: htmlSelected };
      }),
      escapeMarkup: function escapeMarkup(markup) {
        return markup;
      },
      templateResult: function templateResult(selectData) {
        return selectData.html;
      },
      templateSelection: function templateSelection(selectData) {
        return selectData.htmlSelected;
      },
      placeholder: "Estilo"
    });

    $("#fontSelect").on("select2:select", function (e) {
      _this.fonte = e.params.data.text;
      $scope.$apply();
    });
  };

  this.montarSimbolos = function () {
    _this.categoriasSimbolos = Object.assign([], _base_simbolos2.default);
    _this.categoriasSimbolos.forEach(function (categoria) {
      categoria.simbolos.forEach(function (smb) {
        smb.src = "../../images/simbolos/" + categoria.categoria.toLowerCase() + "/" + smb.nome;
      });
    });
  };

  this.limparDesenho = function () {
    $rootScope.modalConfirmacao("Limpar desenho livre", "Tem certeza que deseja limpar?", function () {
      $rootScope.limparLayer();
    });
  };

  $rootScope.limparLayer = function () {
    $("#svgdrawer").children().map(function (i, layers) {
      if ($(layers).prop("tagName") === "path") {
        layers.remove();
      }
    });
  };

  var trabalharComCinza = function trabalharComCinza() {
    var isdark = void 0;
    try {
      isdark = Boolean($("svg").find(".background-joia image").attr("isdark") == "true");
    } catch (err) {
      isdark = false;
      console.warn("SimbolosCtrl - trabalharComCinza", err);
    }
    return isdark;
  };

  this.adicionarTexto = function () {
    $("html, body").scrollTop(0);
    window.imageEditorFull.addLayer({
      type: "text",
      text: _this.texto,
      textFont: _this.fonte, // this.fonte.toLowerCase().replace(/ /g, '_'), // this.fonte,
      textFontSize: "80px",
      textColor: trabalharComCinza() ? "rgba(160,160,160,0.5)" : "rgba(0,0,0,0.4)",
      x: 265,
      y: $rootScope.isAnelOrBracelete() ? 90 : 340
    });
    _this.texto = "";
  };

  this.clickAba = function (aba) {
    if (aba === _this.abaSelecionada) {
      _this.abaSelecionada = null;
      _this.desenhoHabilitado = false;
      _this.removerListenersDesenho();
      _this.habilitarMovimentacaoSimbolos();
      return;
    }
  };

  this.brushSelecionado = function (stroke) {
    return _this.larguraLinha == stroke;
  };

  this.habilitarDesenho = function () {
    var stroke = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "4.0";

    _this.larguraLinha = stroke;
    _this.desenhoHabilitado = true;
    _this.addListenersDesenho();
  };

  this.desabilitarDesenho = function () {
    _this.desenhoHabilitado = false;
    _this.removerListenersDesenho();
  };

  this.selecionarAba = function (aba) {
    $(".accordion").animate({ scrollTop: 0 }, "fast");

    if (aba === "DESENHO") {
      _this.habilitarDesenho(_this.larguraLinha || "4.0");
      _this.desabilitarMovimentacaoSimbolos();
    } else {
      if (aba === "TEXTO") {
        document.getElementById("textoSvgEditor").focus();
      }
      _this.desabilitarDesenho();
      _this.habilitarMovimentacaoSimbolos();
    }
  };

  this.desabilitarMovimentacaoSimbolos = function () {
    $(".draggable-handle").map(function (indice, simbolo) {
      $(simbolo).css("display", "none");
    });
  };

  this.habilitarMovimentacaoSimbolos = function () {
    $(".draggable-handle").map(function (indice, simbolo) {
      $(simbolo).css("display", "");
    });
  };

  this.mouseDown = function (event) {
    if (event.target.id !== "joia" && !$rootScope.isAnelOrBracelete() || $rootScope.isAnelOrBracelete() && event.target.id !== "svgdrawer") {
      return;
    }

    _this.svg = document.getElementById("svgdrawer");

    path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.style.stroke = trabalharComCinza() ? "rgba(160,160,160,0.5)" : "rgba(0,0,0,0.4)";
    path.style.strokeWidth = _this.larguraLinha + "px";
    path.style.fill = "none";
    path.style.strokeLinejoin = "round";
    path.style.strokeLinecap = "round";
    _this.desenhoHabilitado = true;
    data = [];
    _this.svg.appendChild(path);
  };

  this.mouseUp = function (event) {
    _this.desenhoHabilitado = false;
  };

  this.mouseMove = function (event) {
    if (_this.desenhoHabilitado) {
      var coords = _this.getPointFromEvent(event);
      data.push([coords.x, coords.y]);
      var d = "";
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          d += "M" + data[i][0] + " " + data[i][1];
        } else {
          d += " L" + data[i][0] + " " + data[i][1];
        }
      }
      path.setAttribute("d", d);
    }
  };

  this.getPointFromEvent = function (event) {
    var point = { x: 0, y: 0 };
    if (event.targetTouches) {
      var eixoY = $("header").height() + parseInt($(".page.dense").css("padding-top")) + 28; // 95 - era 25
      if ($rootScope.isAnelOrBracelete()) {
        eixoY = $("header").height() + $(".imagemAnel").height() + parseInt($(".page.dense").css("padding-top")) + 117;
      }
      point.x = event.targetTouches[0].clientX - ($(".accordion").width() + $("#menu").width() + 47); // 527
      point.y = event.targetTouches[0].clientY - eixoY;
    } else {
      point.x = event.clientX - 652;
      point.y = event.clientY - 102;
    }
    return point;
  };

  this.addListenersDesenho = function () {
    window.addEventListener("mousedown", _this.mouseDown, false);
    window.addEventListener("mousemove", _this.mouseMove, false);
    window.addEventListener("mouseup", _this.mouseUp, false);
    window.addEventListener("touchstart", _this.mouseDown, false);
    window.addEventListener("touchmove", _this.mouseMove, false);
    window.addEventListener("touchend", _this.mouseUp, false);
  };

  this.removerListenersDesenho = function () {
    window.removeEventListener("mousedown", _this.mouseDown, false);
    window.removeEventListener("mousemove", _this.mouseMove, false);
    window.removeEventListener("mouseup", _this.mouseUp, false);
    window.removeEventListener("touchstart", _this.mouseDown, false);
    window.removeEventListener("touchmove", _this.mouseMove, false);
    window.removeEventListener("touchend", _this.mouseUp, false);
  };

  var changeZooom = function changeZooom() {
    if (!window.layerSelecionada) {
      return;
    }

    window.layerSelecionada.set({
      width: window.layerSelecionada.get("originalwidth") * _this.zooom,
      height: window.layerSelecionada.get("originalheight") * _this.zooom,
      zooom: _this.zooom
    });
  };

  this.montarSimbolos();

  var updateZoom = function updateZoom() {
    var defzooom = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1.0;

    defzooom = defzooom > 1.85 ? 1.85 : defzooom;
    defzooom = defzooom < 0.15 ? 0.15 : defzooom;
    _this.zooom = defzooom;
    $(".focused").val(defzooom);
    changeZooom();
  };

  window.addEventListener("layer-selected", function () {
    showZooom = true;
    if (!$scope.$$phase) {
      $scope.$apply();
    }
    var defzooom = window.layerSelecionada.get("zooom");
    updateZoom(defzooom);
  }, false);

  window.addEventListener("layer-deselected", function () {
    showZooom = false;
    $scope.$apply();
  });

  this.mostrarManipulacao = function () {
    return showZooom;
  };

  this.updateZoom = updateZoom;
  this.changeZooom = changeZooom;
};

exports.default = SimbolosCtrl;

},{"../base_simbolos":3,"../fonts":12}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CPFMask = CPFMask;
function CPFMask() {
  return {
    link: link,
    require: 'ngModel'
  };
  function link(scope, element, attrs, ngModelController) {
    attrs.$set('maxlength', 14);
    scope.$watch(attrs['ngModel'], applyMask);
    function applyMask(event) {
      var value = element.val().replace(/\D/g, '');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      element.val(value);
      if ('asNumber' in attrs) {
        ngModelController.$setViewValue(isNaN(parseInt(value.replace(/\D/g, ''), 10)) ? undefined : parseInt(value.replace(/\D/g, ''), 10));
      } else {
        ngModelController.$setViewValue(value);
      }
    }
  }
}

},{}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TelefoneMask = TelefoneMask;
function TelefoneMask() {
  return {
    link: link,
    require: 'ngModel'
  };
  function link(scope, element, attrs, ngModelController) {
    attrs.$set('maxlength', 14);
    scope.$watch(attrs['ngModel'], applyMask);
    function applyMask(event) {
      var value = element.val().replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '($1)$2');
      value = value.replace(/(\d{5})(\d)/, '$1-$2');
      value = value.replace(/(\d{4})$/, '$1');
      element.val(value);
      if ('asNumber' in attrs) {
        ngModelController.$setViewValue(isNaN(parseInt(value.replace(/\D/g, ''), 10)) ? undefined : parseInt(value.replace(/\D/g, ''), 10));
      } else {
        ngModelController.$setViewValue(value);
      }
    }
  }
}

},{}],12:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = [{
  name: "Anton",
  link: "@font-face { font-family: 'Anton'; src: url('https://fonts.gstatic.com/s/anton/v11/1Ptgg87LROyAm3Kz-C8.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Architects Daughter",
  link: "@font-face { font-family: 'Architects Daughter'; src: url('https://fonts.gstatic.com/s/architectsdaughter/v11/KtkxAKiDZI_td1Lkx62xHZHDtgO_Y-bvTYlg4w.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Amatic SC",
  link: "@font-face { font-family: 'Amatic SC'; src: url('https://fonts.gstatic.com/s/amaticsc/v15/TUZyzwprpvBS1izr_vOECuSf.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "BioRhyme Expanded",
  link: "@font-face { font-family: 'BioRhyme Expanded'; src: url('https://fonts.gstatic.com/s/biorhymeexpanded/v6/i7dQIE1zZzytGswgU577CDY9LjbffxSTT3E.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Bungee Hairline",
  link: "@font-face { font-family: 'Bungee Hairline'; src: url('https://fonts.gstatic.com/s/bungeehairline/v6/snfys0G548t04270a_ljTLUVrv-LZxec.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Caveat",
  link: "@font-face { font-family: 'Caveat'; src: url('https://fonts.gstatic.com/s/caveat/v10/WnznHAc5bAfYB2QRah7pcpNvOx-pjfJ9eIWpYQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Cinzel",
  link: "@font-face { font-family: 'Cinzel'; src: url('https://fonts.gstatic.com/s/cinzel/v11/8vIU7ww63mVu7gtR-kwKxNvkNOjw-tbnfY3lDQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Codystar",
  link: "@font-face { font-family: 'Codystar'; src: url('https://fonts.gstatic.com/s/codystar/v8/FwZY7-Q1xVk-40qxOu6H6Mk.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Courier Prime",
  link: "@font-face { font-family: 'Courier Prime'; src: url('https://fonts.gstatic.com/s/courierprime/v2/u-450q2lgwslOqpF_6gQ8kELawFpWg.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Cutive Mono",
  link: "@font-face { font-family: 'Cutive Mono'; src: url('https://fonts.gstatic.com/s/cutivemono/v9/m8JWjfRfY7WVjVi2E-K9H6RCTm4.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Dancing Script",
  link: "@font-face { font-family: 'Dancing Script'; src: url('https://fonts.gstatic.com/s/dancingscript/v13/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Federo",
  link: "@font-face { font-family: 'Federo'; src: url('https://fonts.gstatic.com/s/federo/v12/iJWFBX-cbD_ETsbWilmf.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Geo",
  link: "@font-face { font-family: 'Geo'; src: url('https://fonts.gstatic.com/s/geo/v12/CSRz4zRZluflKHpn.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Great Vibes",
  link: "@font-face { font-family: 'Great Vibes'; src: url('https://fonts.gstatic.com/s/greatvibes/v8/RWmMoKWR9v4ksMfaWd_JN9XFiaQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Hachi Maru Pop",
  link: "@font-face { font-family: 'Hachi Maru Pop'; src: url('https://fonts.gstatic.com/s/hachimarupop/v3/HI_TiYoRLqpLrEiMAuO9Ysfz7rWweN_ZpK1OtgnSaXe2-6ouLxxoAO2Opg.118.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Hi Melody",
  link: "@font-face { font-family: 'Hi Melody'; src: url('https://fonts.gstatic.com/s/himelody/v8/46ktlbP8Vnz0pJcqCTbEegdS3V8yduAsxfUg1BUTwBF4g3aW.119.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Indie Flower",
  link: "@font-face { font-family: 'Indie Flower'; src: url('https://fonts.gstatic.com/s/indieflower/v12/m8JVjfNVeKWVnh3QMuKkFcZVaUuH.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Italiana",
  link: "@font-face { font-family: 'Italiana'; src: url('https://fonts.gstatic.com/s/italiana/v9/QldNNTtLsx4E__B0XQmWaXw.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Josefin Slab",
  link: "@font-face { font-family: 'Josefin Slab'; src: url('https://fonts.gstatic.com/s/josefinslab/v13/lW-swjwOK3Ps5GSJlNNkMalNpiZe_ldbOR4W71msR349Kg.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Julius Sans One",
  link: "@font-face { font-family: 'Julius Sans One'; src: url('https://fonts.gstatic.com/s/juliussansone/v9/1Pt2g8TAX_SGgBGUi0tGOYEga5WOwnsX.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Jura",
  link: "@font-face { font-family: 'Jura'; src: url('https://fonts.gstatic.com/s/jura/v16/z7NOdRfiaC4Vd8hhoPzfb5vBTP1d7ZumR_g.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Lexend Zetta",
  link: "@font-face { font-family: 'Lexend Zetta'; src: url('https://fonts.gstatic.com/s/lexendzetta/v7/ll87K2KYXje7CdOFnEWcU8sYkS4f.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Lobster",
  link: "@font-face { font-family: 'Lobster'; src: url('https://fonts.gstatic.com/s/lobster/v22/neILzCirqoswsqX9zoKmMw.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Londrina Sketch",
  link: "@font-face { font-family: 'Londrina Sketch'; src: url('https://fonts.gstatic.com/s/londrinasketch/v9/c4m41npxGMTnomOHtRU68eIJn8qvXmP4.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Major Mono Display",
  link: "@font-face { font-family: 'Major Mono Display'; src: url('https://fonts.gstatic.com/s/majormonodisplay/v5/RWmVoLyb5fEqtsfBX9PDZIGr2tFubRh7DXeR.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Montserrat Alternates",
  link: "@font-face { font-family: 'Montserrat Alternates'; src: url('https://fonts.gstatic.com/s/montserratalternates/v12/mFTvWacfw6zH4dthXcyms1lPpC8I_b0juU0566fQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Nanum Myeongjo",
  link: "@font-face { font-family: 'Nanum Myeongjo'; src: url('https://fonts.gstatic.com/s/nanummyeongjo/v15/9Btx3DZF0dXLMZlywRbVRNhxy1LuEGI-gZ_Ll9dMHVruCTvHYAnNT2g.119.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Nova Mono",
  link: "@font-face { font-family: 'Nova Mono'; src: url('https://fonts.gstatic.com/s/novamono/v11/Cn-0JtiGWQ5Ajb--MRKvZ2ZZ.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Nunito",
  link: "@font-face { font-family: 'Nunito'; src: url('https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaB.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Pacifico",
  link: "@font-face { font-family: 'Pacifico'; src: url('https://fonts.gstatic.com/s/pacifico/v16/FwZY7-Qmy14u9lezJ-6H6Mk.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Parisienne",
  link: "@font-face { font-family: 'Parisienne'; src: url('https://fonts.gstatic.com/s/parisienne/v8/E21i_d3kivvAkxhLEVZpQyhwDw.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Patrick Hand",
  link: "@font-face { font-family: 'Patrick Hand'; src: url('https://fonts.gstatic.com/s/patrickhand/v14/LDI1apSQOAYtSuYWp8ZhfYe8XsLL.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Philosopher",
  link: "@font-face { font-family: 'Philosopher'; src: url('https://fonts.gstatic.com/s/philosopher/v14/vEFV2_5QCwIS4_Dhez5jcWBuT00.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Pinyon Script",
  link: "@font-face { font-family: 'Pinyon Script'; src: url('https://fonts.gstatic.com/s/pinyonscript/v11/6xKpdSJbL9-e9LuoeQiDRQR8WOXaOg.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Poiret One",
  link: "@font-face { font-family: 'Poiret One'; src: url('https://fonts.gstatic.com/s/poiretone/v9/UqyVK80NJXN4zfRgbdfbo55cVw.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Pompiere",
  link: "@font-face { font-family: 'Pompiere'; src: url('https://fonts.gstatic.com/s/pompiere/v10/VEMyRoxis5Dwuyeov5Wq7DE.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Reenie Beanie",
  link: "@font-face { font-family: 'Reenie Beanie'; src: url('https://fonts.gstatic.com/s/reeniebeanie/v11/z7NSdR76eDkaJKZJFkkjuvWxXPq1qw.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Roboto",
  link: "@font-face { font-family: 'Roboto'; src: url('https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxK.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Sacramento",
  link: "@font-face { font-family: 'Sacramento'; src: url('https://fonts.gstatic.com/s/sacramento/v8/buEzpo6gcdjy0EiZMBUG4C0f_Q.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Shadows Into Light",
  link: "@font-face { font-family: 'Shadows Into Light'; src: url('https://fonts.gstatic.com/s/shadowsintolight/v10/UqyNK9UOIntux_czAvDQx_ZcHqZXBNQzdcD5.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Share Tech Mono",
  link: "@font-face { font-family: 'Share Tech Mono'; src: url('https://fonts.gstatic.com/s/sharetechmono/v10/J7aHnp1uDWRBEqV98dVQztYldFcLowEF.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Source Code Pro",
  link: "@font-face { font-family: 'Source Code Pro'; src: url('https://fonts.gstatic.com/s/sourcecodepro/v13/HI_SiYsKILxRpg3hIP6sJ7fM7PqlPevW.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Spartan",
  link: "@font-face { font-family: 'Spartan'; src: url('https://fonts.gstatic.com/s/spartan/v3/l7gAbjR61M69yt8Z8w6FZf9WoBxdBrGFuV6JABQ.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Special Elite",
  link: "@font-face { font-family: 'Special Elite'; src: url('https://fonts.gstatic.com/s/specialelite/v11/XLYgIZbkc4JPUL5CVArUVL0ntnAOSA.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Swanky and Moo Moo",
  link: "@font-face { font-family: 'Swanky and Moo Moo'; src: url('https://fonts.gstatic.com/s/swankyandmoomoo/v10/flUlRrKz24IuWVI_WJYTYcqbEsMUZ3kksrnl.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Tangerine",
  link: "@font-face { font-family: 'Tangerine'; src: url('https://fonts.gstatic.com/s/tangerine/v12/IurY6Y5j_oScZZow4VOxCZZM.woff2') format('woff2'); font-weight: normal; font-style: normal; }"
}, {
  name: "Times New Roman",
  link: ""
}];

},{}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = [{ loja: 'Center Shopping Uberlândia', email: 'lojaudi@sethstore.com.br' }, { loja: 'Pátio Savassi BH', email: 'lojapatio@sethstore.com.br' }, { loja: 'BH Shopping', email: 'lojabh@sethstore.com.br' }, { loja: 'Boulevard BH', email: 'lojaboulevard@sethstore.com.br' }, { loja: 'E-commerce BH', email: '' }, { loja: 'Confins BH', email: '' }];

},{}]},{},[1])

