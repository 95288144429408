const app = angular.module('app', [])

import Pagina from './constants/Pagina'

import GaleriaCtrl from './controllers/GaleriaCtrl'
import IndexCtrl from './controllers/IndexCtrl'
import LoginCtrl from './controllers/LoginCtrl'
import SimbolosCtrl from './controllers/SimbolosCtrl'
import FormularioCtrl from './controllers/FormularioCtrl'
import { CPFMask } from './directives/cpf'
import { TelefoneMask } from './directives/telefone'

app.constant('Pagina', Pagina)
app.controller('LoginCtrl', LoginCtrl)
app.controller('GaleriaCtrl', GaleriaCtrl)
app.controller('IndexCtrl', IndexCtrl)
app.controller('SimbolosCtrl', SimbolosCtrl)
app.controller('FormularioCtrl', FormularioCtrl)
app.directive('cpf', CPFMask)
app.directive('telefone', TelefoneMask)

app.config(['$locationProvider', function ($locationProvider) {
    $locationProvider.html5Mode({ enabled: true, requireBase: false }).hashPrefix('!')
}])
