import baseSimbolos from "../base_simbolos";
import fonts from "../fonts";

export default class SimbolosCtrl {
  // @ngInject
  constructor(Pagina, $rootScope, $scope) {
    this.pagina = Pagina.simbolos;
    this.desenhoHabilitado = false;
    this.abaSelecionada = null;
    this.svg = document.getElementById("svgdrawer");
    this.larguraLinha = "4.0";
    this.fonts = fonts.map((font) => font.name);
    this.fonte = "Times New Roman";
    this.layerSelecionada = null;
    this.imageEditorWidth = $("#imageEditor").width();

    var data = [],
      path,
      showZooom = false;

    this.$onInit = () => {
      $("#fontSelect").select2({
        data: fonts.map((font) => {
          const html = `<div style="color: black; font-family: ${font.name}">${font.name}</div>`;
          const htmlSelected = `<div style="color: black; font-family: ${font.name}; font-size: 24px">${font.name}</div>`;
          return { id: font.name, text: font.name, html, htmlSelected };
        }),
        escapeMarkup: function (markup) {
          return markup;
        },
        templateResult: function (selectData) {
          return selectData.html;
        },
        templateSelection: function (selectData) {
          return selectData.htmlSelected;
        },
        placeholder: "Estilo",
      });

      $("#fontSelect").on("select2:select", (e) => {
        this.fonte = e.params.data.text;
        $scope.$apply();
      });
    };

    this.montarSimbolos = () => {
      this.categoriasSimbolos = Object.assign([], baseSimbolos);
      this.categoriasSimbolos.forEach((categoria) => {
        categoria.simbolos.forEach((smb) => {
          smb.src = `../../images/simbolos/${categoria.categoria.toLowerCase()}/${
            smb.nome
          }`;
        });
      });
    };

    this.limparDesenho = () => {
      $rootScope.modalConfirmacao(
        "Limpar desenho livre",
        "Tem certeza que deseja limpar?",
        () => {
          $rootScope.limparLayer();
        }
      );
    };

    $rootScope.limparLayer = () => {
      $("#svgdrawer")
        .children()
        .map((i, layers) => {
          if ($(layers).prop("tagName") === "path") {
            layers.remove();
          }
        });
    };

    const trabalharComCinza = () => {
      let isdark;
      try {
        isdark = Boolean(
          $("svg").find(".background-joia image").attr("isdark") == "true"
        );
      } catch (err) {
        isdark = false;
        console.warn("SimbolosCtrl - trabalharComCinza", err);
      }
      return isdark;
    };

    this.adicionarTexto = () => {
      $("html, body").scrollTop(0);
      window.imageEditorFull.addLayer({
        type: "text",
        text: this.texto,
        textFont: this.fonte, // this.fonte.toLowerCase().replace(/ /g, '_'), // this.fonte,
        textFontSize: "80px",
        textColor: trabalharComCinza()
          ? "rgba(160,160,160,0.5)"
          : "rgba(0,0,0,0.4)",
        x: 265,
        y: $rootScope.isAnelOrBracelete() ? 90 : 340,
      });
      this.texto = "";
    };

    this.clickAba = (aba) => {
      if (aba === this.abaSelecionada) {
        this.abaSelecionada = null;
        this.desenhoHabilitado = false;
        this.removerListenersDesenho();
        this.habilitarMovimentacaoSimbolos();
        return;
      }
    };

    this.brushSelecionado = (stroke) => {
      return this.larguraLinha == stroke;
    };

    this.habilitarDesenho = (stroke = "4.0") => {
      this.larguraLinha = stroke;
      this.desenhoHabilitado = true;
      this.addListenersDesenho();
    };

    this.desabilitarDesenho = () => {
      this.desenhoHabilitado = false;
      this.removerListenersDesenho();
    };

    this.selecionarAba = (aba) => {
      $(".accordion").animate({ scrollTop: 0 }, "fast");

      if (aba === "DESENHO") {
        this.habilitarDesenho(this.larguraLinha || "4.0");
        this.desabilitarMovimentacaoSimbolos();
      } else {
        if (aba === "TEXTO") {
          document.getElementById("textoSvgEditor").focus();
        }
        this.desabilitarDesenho();
        this.habilitarMovimentacaoSimbolos();
      }
    };

    this.desabilitarMovimentacaoSimbolos = () => {
      $(".draggable-handle").map((indice, simbolo) => {
        $(simbolo).css("display", "none");
      });
    };

    this.habilitarMovimentacaoSimbolos = () => {
      $(".draggable-handle").map((indice, simbolo) => {
        $(simbolo).css("display", "");
      });
    };

    this.mouseDown = (event) => {
      if (
        (event.target.id !== "joia" && !$rootScope.isAnelOrBracelete()) ||
        ($rootScope.isAnelOrBracelete() && event.target.id !== "svgdrawer")
      ) {
        return;
      }

      this.svg = document.getElementById("svgdrawer");

      path = document.createElementNS("http://www.w3.org/2000/svg", "path");
      path.style.stroke = trabalharComCinza()
        ? "rgba(160,160,160,0.5)"
        : "rgba(0,0,0,0.4)";
      path.style.strokeWidth = `${this.larguraLinha}px`;
      path.style.fill = "none";
      path.style.strokeLinejoin = "round";
      path.style.strokeLinecap = "round";
      this.desenhoHabilitado = true;
      data = [];
      this.svg.appendChild(path);
    };

    this.mouseUp = (event) => {
      this.desenhoHabilitado = false;
    };

    this.mouseMove = (event) => {
      if (this.desenhoHabilitado) {
        var coords = this.getPointFromEvent(event);
        data.push([coords.x, coords.y]);
        var d = "";
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            d += "M" + data[i][0] + " " + data[i][1];
          } else {
            d += " L" + data[i][0] + " " + data[i][1];
          }
        }
        path.setAttribute("d", d);
      }
    };

    this.getPointFromEvent = (event) => {
      let point = { x: 0, y: 0 };
      if (event.targetTouches) {
        let eixoY =
          $("header").height() +
          parseInt($(".page.dense").css("padding-top")) +
          28; // 95 - era 25
        if ($rootScope.isAnelOrBracelete()) {
          eixoY =
            $("header").height() +
            $(".imagemAnel").height() +
            parseInt($(".page.dense").css("padding-top")) +
            117;
        }
        point.x =
          event.targetTouches[0].clientX -
          ($(".accordion").width() + $("#menu").width() + 47); // 527
        point.y = event.targetTouches[0].clientY - eixoY;
      } else {
        point.x = event.clientX - 652;
        point.y = event.clientY - 102;
      }
      return point;
    };

    this.addListenersDesenho = () => {
      window.addEventListener("mousedown", this.mouseDown, false);
      window.addEventListener("mousemove", this.mouseMove, false);
      window.addEventListener("mouseup", this.mouseUp, false);
      window.addEventListener("touchstart", this.mouseDown, false);
      window.addEventListener("touchmove", this.mouseMove, false);
      window.addEventListener("touchend", this.mouseUp, false);
    };

    this.removerListenersDesenho = () => {
      window.removeEventListener("mousedown", this.mouseDown, false);
      window.removeEventListener("mousemove", this.mouseMove, false);
      window.removeEventListener("mouseup", this.mouseUp, false);
      window.removeEventListener("touchstart", this.mouseDown, false);
      window.removeEventListener("touchmove", this.mouseMove, false);
      window.removeEventListener("touchend", this.mouseUp, false);
    };

    const changeZooom = () => {
      if (!window.layerSelecionada) {
        return;
      }

      window.layerSelecionada.set({
        width: window.layerSelecionada.get("originalwidth") * this.zooom,
        height: window.layerSelecionada.get("originalheight") * this.zooom,
        zooom: this.zooom,
      });
    };

    this.montarSimbolos();

    const updateZoom = (defzooom = 1.0) => {
      defzooom = defzooom > 1.85 ? 1.85 : defzooom;
      defzooom = defzooom < 0.15 ? 0.15 : defzooom;
      this.zooom = defzooom;
      $(".focused").val(defzooom);
      changeZooom();
    };

    window.addEventListener(
      "layer-selected",
      function () {
        showZooom = true;
        if (!$scope.$$phase) {
          $scope.$apply();
        }
        let defzooom = window.layerSelecionada.get("zooom");
        updateZoom(defzooom);
      },
      false
    );

    window.addEventListener("layer-deselected", function () {
      showZooom = false;
      $scope.$apply();
    });

    this.mostrarManipulacao = () => {
      return showZooom;
    };

    this.updateZoom = updateZoom;
    this.changeZooom = changeZooom;
  }
}
