export default [
  {
    categoria: 'ANEIS',
    produtos: [
      {
        id: 'imagem77',
        nome: '2.png',
        height: '1391',
        width: '600',
        categoria: 'ANEIS'
      },
      {
        id: 'imagem78',
        nome: '3.png',
        height: '1385',
        width: '600',
        categoria: 'ANEIS'
      }
    ]
  },
  {
    categoria: 'BRACELETES',
    produtos: [
      {
        id: 'imagem76',
        nome: '1.png',
        height: '1391',
        width: '600',
        categoria: 'BRACELETES'
      },
      {
        id: 'imagem79',
        nome: '4.png',
        height: '1386',
        width: '600',
        categoria: 'BRACELETES'
      },
    ]
  },
  {
    categoria: 'PINGENTES',
    produtos: [
      {
        id: 'imagem24',
        nome: '1.png',
        height: '888',
        width: '572',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem25',
        nome: '2.png',
        height: '894',
        width: '705',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem26',
        nome: '3.png',
        height: '708',
        width: '552',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem27',
        nome: '4.png',
        height: '990',
        width: '732',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem29',
        nome: '5.png',
        height: '984',
        width: '840',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem30',
        nome: '6.png',
        height: '984',
        width: '768',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem31',
        nome: '7.png',
        height: '1200',
        width: '492',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem32',
        nome: '8.png',
        height: '800',
        width: '590',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem32',
        nome: '9.png',
        height: '1000',
        width: '708',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem32',
        nome: '10.png',
        height: '900',
        width: '384',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem32',
        nome: '11.png',
        height: '908',
        width: '252',
        categoria: 'PINGENTES'
      },
      {
        id: 'imagem32',
        nome: '12.png',
        height: '708',
        width: '552',
        categoria: 'PINGENTES'
      }
    ]
  },
  {
    categoria: 'PULSEIRAS',
    produtos: [
      {
        id: 'imagem33',
        nome: '1.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem34',
        nome: '2.png',
        height: '984',
        width: '800',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem35',
        nome: '3.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem36',
        nome: '4.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem37',
        nome: '5.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem38',
        nome: '6.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem39',
        nome: '7.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem40',
        nome: '8.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem41',
        nome: '9.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem42',
        nome: '10.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem43',
        nome: '11.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem44',
        nome: '12.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem45',
        nome: '13.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem46',
        nome: '14.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem47',
        nome: '15.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem48',
        nome: '16.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem49',
        nome: '17.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem50',
        nome: '18.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem51',
        nome: '19.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem52',
        nome: '20.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem53',
        nome: '21.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem54',
        nome: '22.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem55',
        nome: '23.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem56',
        nome: '24.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem57',
        nome: '25.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem58',
        nome: '26.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem59',
        nome: '27.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem60',
        nome: '28.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem61',
        nome: '29.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem62',
        nome: '30.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem63',
        nome: '31.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem64',
        nome: '32.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem65',
        nome: '33.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem66',
        nome: '34.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem67',
        nome: '35.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem68',
        nome: '36.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem69',
        nome: '37.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem70',
        nome: '38.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem71',
        nome: '39.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem72',
        nome: '40.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem73',
        nome: '41.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem74',
        nome: '42.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem75',
        nome: '43.png',
        height: '984',
        width: '840',
        isdark: true,
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem79',
        nome: '44.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem80',
        nome: '45.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem81',
        nome: '46.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem82',
        nome: '47.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem83',
        nome: '48.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem84',
        nome: '49.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem85',
        nome: '50.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem86',
        nome: '51.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem87',
        nome: '52.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem88',
        nome: '53.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem89',
        nome: '54.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem90',
        nome: '55.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem91',
        nome: '56.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem92',
        nome: '57.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem93',
        nome: '58.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      },
      {
        id: 'imagem94',
        nome: '59.png',
        height: '984',
        width: '840',
        categoria: 'PULSEIRAS'
      }
    ]
  }
]
